/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FilterListIcon from '@mui/icons-material/Sort';
import {
  Box,
  IconButton,
  Tab,
  Tabs as TabsContainer,
  Tooltip
} from '@mui/material';
import { useSortTableMutation } from 'api/calculationDictionary';
import { ReactComponent as SuccessIcon } from 'assets/icons/cloudSuccessWhite.svg';
import { ImportButton } from 'pages/Administration/AdminReferences/Prices/components/PricesReferences.style';
import React, { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CalcData } from 'types';
import { HandbookContext, TTabType } from '../handbook.services';
import Stepper from '../../../../../components/Stepper';
import Button from '../../../../../components/Button';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';

type TTabsProps = {
  changeTab: (event: React.SyntheticEvent, tabName: TTabType) => void;
  selectedTab: TTabType;
  calculation?: CalcData;
};

const Tabs: React.FC<TTabsProps> = ({
  selectedTab,
  changeTab,
  calculation
}) => {
  // ******* Search Params *******
  const { calcID: searchCalcID } = useParams();
  const calcID = searchCalcID ? +searchCalcID : 0;

  // ******* Отсортировать ресурсы *******
  const {
    setAnchorEl,
    setParametersDialogOpen,
    currentAct,
    toggleIntegrate,
    setCreateDrawer,
    removedIds,
    selectedRow,
    deleteRatesWithPositions,
    maxDepth,
    currentDepth,
    setCurrentDepth
  } = useContext(HandbookContext);

  // ******* Отсортировать ресурсы *******
  const [sortTable] = useSortTableMutation();
  const handleRefreshTable = useCallback(() => {
    if (calcID) {
      sortTable(calcID);
    }
  }, [calcID, sortTable]);

  const Content = React.useMemo(() => {
    switch (selectedTab) {
      case 'calculation':
        // console.log('Log ', currentDepth);
        return (
          <>
            <Tooltip title={'Отсортировать ресурсы'}>
              <IconButton onClick={handleRefreshTable}>
                <FilterListIcon color={'primary'} fontSize={'small'} />
              </IconButton>
            </Tooltip>
            <Button
              onClick={() => deleteRatesWithPositions?.()}
              disabled={!removedIds.length}
              variant={'text'}>
              <Box display={'flex'} gap={'8px'} alignItems={'center'}>
                <DeleteOutlineIcon fontSize={'small'} />
                Удалить
              </Box>
            </Button>
            <ImportButton
              data-dictionary={true}
              disabled={!selectedRow}
              variant={'text'}
              onClick={() => setCreateDrawer(true)}>
              <div data-dictionary={true}>
                <EditIcon data-dictionary={true} />
                Справочник
              </div>
            </ImportButton>
            <Stepper
              count={maxDepth + 1}
              page={currentDepth.page}
              onChange={(ev, page) => {
                // console.log('Log ', ev);
                if (page > currentDepth.page) {
                  setCurrentDepth({ page, direction: 'asc' });
                } else if (maxDepth === currentDepth.page) {
                  setCurrentDepth({ page, direction: 'desc' });
                }
                // else if (page === 1) {
                //   setCurrentDepth({ page, direction: 'asc' });
                // }
                else {
                  setCurrentDepth({ page, direction: 'desc' });
                }
              }}
            />
            {calculation?.integrationInfo && (
              <Button
                disabled={!currentAct}
                onClick={() => toggleIntegrate(true)}
                style={{
                  padding: '8px',
                  minWidth: '40px'
                }}>
                <SuccessIcon />
              </Button>
            )}
            <ImportButton
              variant={'text'}
              onClick={() => {
                setParametersDialogOpen(true);
              }}>
              <div>
                <FormatListBulletedIcon />
                параметры расчета
              </div>
            </ImportButton>
            <Button
              onClick={(e) => setAnchorEl(e.currentTarget)}
              style={{
                padding: '8px',
                minWidth: '40px'
              }}>
              <MoreHorizIcon />
            </Button>
          </>
        );
      case 'vor':
        return (
          <>
            {calculation?.integrationInfo && (
              <Button
                disabled={!currentAct}
                onClick={() => toggleIntegrate(true)}
                style={{
                  padding: '8px',
                  minWidth: '40px'
                }}>
                <SuccessIcon />
              </Button>
            )}
            <ImportButton
              variant={'text'}
              onClick={() => {
                setParametersDialogOpen(true);
              }}>
              <div>
                <FormatListBulletedIcon />
                параметры расчета
              </div>
            </ImportButton>
            <Button
              onClick={(e) => setAnchorEl(e.currentTarget)}
              style={{
                padding: '8px',
                minWidth: '40px'
              }}>
              <MoreHorizIcon />
            </Button>
          </>
        );
      case 'executed':
        return (
          <>
            <Stepper
              count={maxDepth + 1}
              page={currentDepth.page}
              onChange={(ev, page) => {
                // console.log('Log ', ev);
                if (page > currentDepth.page) {
                  setCurrentDepth({ page, direction: 'asc' });
                } else if (maxDepth === currentDepth.page) {
                  setCurrentDepth({ page, direction: 'desc' });
                }
                // else if (page === 1) {
                //   setCurrentDepth({ page, direction: 'asc' });
                // }
                else {
                  setCurrentDepth({ page, direction: 'desc' });
                }
              }}
            />
            {calculation?.integrationInfo && (
              <Button
                disabled={!currentAct}
                onClick={() => toggleIntegrate(true)}
                style={{
                  padding: '8px',
                  minWidth: '40px'
                }}>
                <SuccessIcon />
              </Button>
            )}
            <ImportButton
              variant={'text'}
              onClick={() => {
                setParametersDialogOpen(true);
              }}>
              <div>
                <FormatListBulletedIcon />
                параметры актов
              </div>
            </ImportButton>
            <Button
              onClick={(e) => setAnchorEl(e.currentTarget)}
              style={{
                padding: '8px',
                minWidth: '40px'
              }}>
              <MoreHorizIcon />
            </Button>
          </>
        );
      default:
        return <></>;
    }
  }, [
    calculation?.integrationInfo,
    maxDepth,
    currentDepth,
    setCurrentDepth,
    currentAct,
    deleteRatesWithPositions,
    handleRefreshTable,
    removedIds.length,
    selectedRow,
    selectedTab,
    setAnchorEl,
    setCreateDrawer,
    setParametersDialogOpen,
    toggleIntegrate
  ]);

  return (
    <Box
      height={'48px'}
      display="flex"
      flexDirection="row"
      justifyContent={'space-between'}
      alignItems="center">
      <TabsContainer value={selectedTab} onChange={changeTab}>
        <Tab value={'calculation'} label={'Расчет'} />
        <Tab value={'vor'} label={'Укрупненные'} />
        <Tab value={'executed'} label={'Выполнение'} />
      </TabsContainer>

      <div style={{ display: 'flex', alignItems: 'stretch', gap: '12px' }}>
        {Content}
      </div>
    </Box>
  );
};

export default Tabs;

import { api } from 'api/api';
import {
  CalcDataType,
  CalcID,
  CalcIDAct,
  EditQuantityRequest,
  RimCoefficientsDataResponse,
  RimCoefficientsUpdateRequest,
  RimExecutionCoefficientsDataResponse,
  UpdateRimExecutionCoefficientsDataResponse
} from 'types';
import {
  GetCoefficientsActResponse,
  GetCoefficientsV3ActResponse,
  GetCoefficientsV3Response,
  GetDynamicLimitListResponse,
  GetLimitedListResponse,
  KSResponse,
  UpdateActRequest,
  UpdateCoefficientsRequest
} from './params.types';

// TODO: пока все одинаковые
const projectTypes: Record<CalcDataType, string> = {
  handbk: 'handbook',
  basisManual: 'complicated',
  excel: 'complicated',
  grandSmeta: 'complicated',
  resourceManual: 'complicated',
  tangl: 'complicated',
  baseIndex: 'complicated',
  resourceIndex: 'complicated'
};

export const params = api.injectEndpoints({
  endpoints: (build) => ({
    getCoefficientsV3: build.query<GetCoefficientsV3Response, CalcID>({
      query: ({ calcID }) => ({
        url: `/calculation/${calcID}/complicated/bim/coefficients/get`,
        // url: `/calculation/${calcID}/complicated/bim/coefficients/get`,
        method: 'GET'
      }),
      providesTags: ['Params']
    }),

    updateCoefficientsV3: build.mutation<
      UpdateCoefficientsRequest,
      { calcID: number; values: UpdateCoefficientsRequest }
    >({
      query: (body) => ({
        url: `/calculation/${body.calcID}/complicated/bim/coefficients/update`,
        method: 'PATCH',
        body: body.values
      }),

      invalidatesTags: [
        'Params',
        'СomplicatedList',
        'ActList',
        'DynamicRows',
        'ComplexList',
        'HandbookExecutionList'
      ]
    }),

    getCoefficientsV3Act: build.query<
      GetCoefficientsV3ActResponse,
      Omit<CalcIDAct, 'projectType'>
    >({
      query: ({ calcID, actID }) => ({
        url: `/calculation/${calcID}/execution/acts/${actID}/coefficients/complicated/get`,
        method: 'GET'
      }),

      providesTags: ['Params', 'DynamicRows']
    }),

    syncCoefficientsAct: build.mutation<GetCoefficientsActResponse, CalcIDAct>({
      query: ({ calcID, actID, projectType }) => {
        return {
          url: `/calculation/${calcID}/execution/acts/${actID}/coefficients/${projectTypes[projectType]}/sync`,
          method: 'GET'
        };
      },
      invalidatesTags: [
        'Params',
        'СomplicatedList',
        'ActList',
        'CalculationCoefficients',
        'ExecutionRimList'
      ]
    }),

    updateCoefficientsActV3: build.mutation<
      UpdateCoefficientsRequest,
      { calcID: number; actID: number; values: UpdateCoefficientsRequest }
    >({
      query: (body) => ({
        // url: `/calculation/${body.calcID}/complicated/execution/acts/${body.actID}/coefficients/update`,
        url: `/calculation/${body.calcID}/execution/acts/${body.actID}/coefficients/complicated/update`,
        method: 'POST',
        body: body.values
      }),

      invalidatesTags: [
        'СomplicatedList',
        'ActList',
        'HandbookExecutionList',
        'CalculationCoefficients'
      ]
    }),
    updateAct: build.mutation<
      unknown,
      { calcID: number; values: UpdateActRequest[] }
    >({
      query: (body) => {
        console.log(body);
        return {
          url: `/calculation/${body.calcID}/complicated/execution/acts/batch/edit`,
          method: 'POST',
          body: { data: body.values }
        };
      },

      invalidatesTags: [
        'СomplicatedList',
        'ActList',
        'ExecutionList',
        'HandbookExecutionList',
        'CalculationCoefficients',
        'Params',
        'ExecutionRimList'
      ]
    }),
    getLimitedList: build.query<GetLimitedListResponse, number>({
      query: (calcID) => ({
        url: `/calculation/${calcID}/complicated/execution/dynamic-rows-list`,
        method: 'GET'
      }),
      providesTags: ['DynamicRows']
    }),
    getKS2V2: build.query<KSResponse, { calcID: number; actID: number }>({
      query: (body) => ({
        url: `/calculation/${body.calcID}/execution/acts/${body.actID}/get-ks2`,
        method: 'GET'
        // responseHandler: (response: { text: () => string }) => response.text(),
      })
    }),
    getKS6V2: build.query<KSResponse, { calcID: number; actID: number }>({
      query: (body) => ({
        url: `/calculation/${body.calcID}/execution/acts/${body.actID}/get-ks6a`,
        method: 'GET'
        // responseHandler: (response: { text: () => string }) => response.text(),
      })
    }),
    deleteAct: build.mutation<unknown, { calcID: number; actID: number }>({
      query: (body) => ({
        url: `/calculation/${body.calcID}/execution/acts/${body.actID}/delete`,
        // url: `/calculation/${body.calcID}/complicated/execution/acts/${body.actID}/delete`,
        method: 'DELETE'
      }),

      invalidatesTags: [
        'СomplicatedList',
        'ActList',
        'HandbookExecutionList',
        'CalculationCoefficients',
        'ExecutionRimList'
      ]
    }),

    /* коэффициенты РИМ */

    getRimCoefficients: build.query<RimCoefficientsDataResponse, CalcID>({
      query: ({ calcID }) => ({
        url: `/calculation/${calcID}/complicated/rim/coefficients/get`,
        method: 'GET'
      }),
      providesTags: ['RimCoefficients']
    }),
    updateRimCoefficients: build.mutation<
      unknown,
      CalcID & RimCoefficientsUpdateRequest
    >({
      query: ({ calcID, limitedCosts }) => ({
        url: `/calculation/${calcID}/complicated/rim/coefficients/update`,
        method: 'PUT',
        body: { limitedCosts }
      }),
      invalidatesTags: [
        'RimCoefficients',
        'IndexMethodList',
        'ExecutionRimList',
        'RimExecutionCoefficients',
        'DynamicRimRows'
      ]
    }),

    getRimExecutionCoefficients: build.query<
      RimExecutionCoefficientsDataResponse,
      Omit<EditQuantityRequest, 'body'>
    >({
      query: ({ calcID, actID }) => ({
        url: `/calculation/${calcID}/execution/acts/${actID}/coefficients/complicated/rim/get`,
        method: 'GET'
      }),
      keepUnusedDataFor: 0,
      providesTags: ['RimExecutionCoefficients']
    }),
    getDynamicLimits: build.query<GetDynamicLimitListResponse[], CalcID>({
      query: ({ calcID }) => ({
        url: `/calculation/${calcID}/complicated/execution/rim/dynamic-rows-list`,
        method: 'GET'
      }),
      providesTags: ['DynamicRimRows']
    }),
    updateRimExecutionCoefficients: build.mutation<
      RimExecutionCoefficientsDataResponse,
      UpdateRimExecutionCoefficientsDataResponse
    >({
      query: ({ calcID, actID, body }) => ({
        url: `/calculation/${calcID}/execution/acts/${actID}/coefficients/complicated/rim/update`,
        method: 'POST',
        body
      })
    })
  }),
  overrideExisting: false
});

export const {
  useGetCoefficientsV3ActQuery,
  useGetCoefficientsV3Query,

  useSyncCoefficientsActMutation,

  useUpdateCoefficientsV3Mutation,
  useUpdateCoefficientsActV3Mutation,

  useUpdateActMutation,

  useDeleteActMutation,

  useLazyGetKS2V2Query,
  useLazyGetKS6V2Query,

  useGetLimitedListQuery,

  useGetRimCoefficientsQuery,
  useUpdateRimCoefficientsMutation,

  useGetRimExecutionCoefficientsQuery,
  useGetDynamicLimitsQuery,
  useUpdateRimExecutionCoefficientsMutation
} = params;

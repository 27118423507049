import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import { PositionVOR, PositionVORWithTarget } from 'api/calculations/types';
import Button from 'components/Button';
import { ToggleButtonGroup } from 'components/ToggleButtonGroup';
import Tooltip from 'components/Tooltip';
import { useCalcId } from 'hooks/useCalcId';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  calculationsApi,
  useBatchCreateMutation
} from '../../../../api/calculations';
import {
  changeModalState,
  clearWorks,
  setExtraWorks,
  setMainWorks
} from '../../../../store/slices/vor/vor';
import { RootState, useAppDispatch } from '../../../../store/store';
import { toggleButtons } from './TableLegend.constants';
import {
  ButtonWrapper,
  StyledCount,
  StyledToggleButton,
  StyledToggleButtonWrapper,
  TableLegendFormButton,
  TableLegendWrapper
} from './TableLegend.styles';
import { TableLegendProps } from './TableLegend.types';

export const TableLegend: FC<TableLegendProps> = ({
  selectedTableVariant,
  onTableVariantChange,
  works,
  redirect,
  handleOpenModal,
  countPositions,
  countGroupedPositions,
  isMainWorks,
  clearWorksInRTK,
  selected,
  selectPositions,
  dispatchVor,
  setSelectPosition,
  resetFilters
}) => {
  const calcID = useCalcId();

  const [batchCreate] = useBatchCreateMutation();

  const handleCreateVorFromPositions = useCallback(() => {
    if (calcID) {
      const uploadedArr = (works[0] as PositionVOR[])
        .filter((position) =>
          'type' in position ? position?.type === 'main' : false
        )
        .map((position) => {
          return {
            ...position,
            objectName: null,
            targetID: position.id,
            unit: position.unitType,
            constructionEffort: position.constructionEffort || 0,
            mechanicsEffort: position.mechanicsEffort || 0,
            header: position.header || '',
            chapter: position.chapter || '',
            code: position.code || '',
            totalWorkCost: position.totalWorkCost || 0
            // id,
            // tz: tz,
            // tzm: tzm,
            // num,
            // dynamicColumns: dynamicItems,
            // quantity: quantity!,
            // total: total!
          };
        });
      batchCreate({
        calcID,
        body: {
          targets: uploadedArr
        }
      }).then(() => {
        works[0].forEach((el) => {
          dispatchVor(el as PositionVORWithTarget, true);
        });
        setSelectPosition([], undefined, works[0] as PositionVOR[]);
        dispatch(clearWorks());
        resetFilters();
      });
    }
  }, [calcID, works]);
  const dispatch = useAppDispatch();
  const { isAddingVor, savingData, deletedRows } = useSelector(
    (state: RootState) => state.vor.table
  );
  const { ConfirmDialog: ConfirmCreateVor, openConfirm: openConfirmCreateVor } =
    useConfirmDialog({
      title: 'Подтвердить формирование расценки?',
      body: 'По выбранным позициям будут сформированы единичные расценки.',
      handleConfirm(confirm, fn) {
        if (confirm) {
          fn?.();
        }
      }
    });
  useEffect(() => {
    if (countGroupedPositions === 0) {
      onTableVariantChange('positions');
    }
  }, [countGroupedPositions]);

  const openModal = () => {
    handleOpenModal(true);
    dispatch(clearWorks());
  };

  const addVorHandler = useCallback(() => {
    if (savingData) {
      const [main, extra] = works;
      const newData = { ...savingData };
      newData.body = {
        ...savingData.body,
        main: [...savingData.body.main, ...main.map((_) => _.id)],
        secondary: [...savingData.body.secondary, ...extra.map((_) => _.id)]
      };
      dispatch(setMainWorks(main));
      dispatch(setExtraWorks(extra));
      dispatch(changeModalState());

      const deletedWorks = [...main, ...extra].filter((item) =>
        deletedRows.find((row) => row.id === item.id)
      );
      if (deletedWorks.length) {
        dispatch(
          calculationsApi.util.updateQueryData(
            'getVorPositions',
            { calcId: savingData.calcId },
            (draft) => {
              Object.assign(draft.data, deletedWorks);
            }
          )
        );
      }
      redirect();
    }
  }, [savingData, isAddingVor, works]);

  const abortAdding = () => {
    clearWorksInRTK();
    dispatch(changeModalState());
    redirect();
  };
  return (
    <TableLegendWrapper>
      <ToggleButtonGroup
        sx={{ height: '32px', boxSizing: 'content-box' }}
        exclusive>
        {toggleButtons
          .filter((value, index) => {
            if (isAddingVor) {
              if (index === 0) {
                return value;
              }
              return null;
            }
            return value;
          })
          .map((button) => (
            <StyledToggleButtonWrapper key={button.value}>
              <StyledToggleButton
                size="small"
                value={button.value}
                selected={button.value === selectedTableVariant}
                disabled={countGroupedPositions === 0}
                onChange={(_, value) => onTableVariantChange(value)}>
                {button.label}
                {button.value !== 'fullVor' && (
                  <StyledCount>
                    {
                      {
                        positions: countPositions,
                        grouped: countGroupedPositions
                      }[button.value]
                    }
                  </StyledCount>
                )}
              </StyledToggleButton>
            </StyledToggleButtonWrapper>
          ))}
      </ToggleButtonGroup>
      <ButtonWrapper>
        {isAddingVor && (
          <TableLegendFormButton
            color="warning"
            disableElevation
            onClick={abortAdding}>
            Отмена
          </TableLegendFormButton>
        )}
        {selectedTableVariant === 'positions' && (
          <TableLegendFormButton
            sx={{
              '&.Mui-disabled': {
                background: '#9AA2B0',
                color: '#fff'
              }
            }}
            disabled={isAddingVor ? !selected : isMainWorks === 0}
            onClick={() => {
              isAddingVor ? addVorHandler() : openModal();
            }}
            color="success"
            disableElevation>
            {isAddingVor ? 'Добавить' : 'Сформировать укрупненную расценку'}
          </TableLegendFormButton>
        )}
        {selectedTableVariant === 'positions' && !isAddingVor && (
          <Tooltip
            // placement={'left'}
            // PopperProps={{ disablePortal: true }}
            title={'Сформировать единичную расценку'}>
            <div>
              <Button
                sx={{
                  padding: '4px 8px !important',
                  height: 'min-content !important',
                  width: 'min-content !important',
                  minWidth: 'min-content !important',
                  maxWidth: '0 !important'
                }}
                onClick={() =>
                  openConfirmCreateVor(handleCreateVorFromPositions)
                }
                disabled={
                  !selectPositions ||
                  (works[1].length ? true : !works[0].length)
                }>
                <DriveFileMoveOutlinedIcon fontSize={'medium'} />
              </Button>
            </div>
          </Tooltip>
        )}
        <ConfirmCreateVor />
      </ButtonWrapper>
    </TableLegendWrapper>
  );
};

export enum ENUMLocalStorage {
  levelsIndexMethod = 'levelsIndexMethod',
  levelsIndexMethodExecute = 'levelsIndexMethodExecute',
  levelsIndexMethodPreview = 'levelsIndexMethodPreview'
}

export type LSlevelsIndexMethodProps =
  | { id: number; levels: number[] }[]
  | undefined;

export interface ILocalStorageValue {
  [ENUMLocalStorage.levelsIndexMethod]: LSlevelsIndexMethodProps;
  [ENUMLocalStorage.levelsIndexMethodExecute]: LSlevelsIndexMethodProps;
  [ENUMLocalStorage.levelsIndexMethodPreview]: LSlevelsIndexMethodProps;

  [key: string]: unknown;
}

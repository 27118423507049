/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import styled from '@emotion/styled';
import FunctionsIcon from '@mui/icons-material/Functions';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ICellRendererParams } from 'ag-grid-community';
import React, { useMemo } from 'react';
import { IComplicated } from '../../../../../../../../api/calculations/types';
import {
  SvgExecutedRate,
  SvgExecutedRatePosition
} from '../../../../../../../../assets/SVG/SVG';
import Cell from '../../../../../Accomplishment/components/Cell';
import { Level, PinnedLevel } from './LevelExecution.style';
import { ExecutedTabCellLevelFolder } from '../../../../../CalculationDirectory/tabs/ExecutedTab';

interface ILevelExecution extends ICellRendererParams<IComplicated, any, any> {
  collapse: (id: number) => void;
  hiddenArr: number[];
}

const EmptySpan = styled.span`
  width: 24px;
  height: 24px;
  margin: 7px;
`;
const LevelExecution: React.FC<ILevelExecution> = ({
  data,
  node,
  collapse,
  hiddenArr
}) => {
  const close = useMemo(() => {
    return hiddenArr.includes(data?.id!);
  }, [hiddenArr]);

  const onClick = () => {
    collapse(data?.id!);
  };

  if (node.rowPinned === 'top') {
    return (
      <PinnedLevel>
        <FunctionsIcon />
        <Cell showTooltip={false}>Суммы</Cell>
      </PinnedLevel>
    );
  }
  const colors = {
    inactive: '#B8B8B8',
    deleted: '#B8B8B8',
    dont_belong: '#FF0101',
    split: '#B78BAC'
  };

  switch (data?.type!) {
    case 'file':
      return (
        <>
          <Level
            style={{ alignItems: 'center', justifyContent: 'center' }}
            onClick={onClick}
            arrowClose={close}>
            {data?.children.length ? (
              <KeyboardArrowUpIcon
                className={'arrow'}
                style={{ position: 'absolute', left: '4px' }}
              />
            ) : (
              <span />
            )}
            <ExecutedTabCellLevelFolder>1</ExecutedTabCellLevelFolder>
          </Level>
        </>
      );
    case 'level':
      return (
        <>
          <Level
            style={{ alignItems: 'center', justifyContent: 'center' }}
            onClick={onClick}
            arrowClose={close}>
            {data?.children.length ? (
              <KeyboardArrowUpIcon
                className={'arrow'}
                style={{ position: 'absolute', left: '4px' }}
              />
            ) : (
              <span />
            )}
            <SvgExecutedRate
              style={{ fill: '#5291DA', width: 17, height: 18 }}
            />
          </Level>
        </>
      );
    case 'row':
      return (
        <>
          <Level
            style={{ alignItems: 'center', justifyContent: 'center' }}
            onClick={onClick}
            arrowClose={close}
            floatRight>
            <span />
            <SvgExecutedRatePosition
              style={{
                width: 16,
                height: 17,
                fill: '#B78BAC'
              }}
            />
          </Level>
        </>
      );
    default:
      return (
        <Cell>
          {!!data?.children.length && (
            <button onClick={() => collapse(data?.id!)}>collabse</button>
          )}
        </Cell>
      );
  }
};

export default LevelExecution;

import styled from 'styled-components';

export const CustomHeaderWrapper = styled('div')<{ isOpen: boolean }>`
  padding: 0 4.5px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #00358c;
  line-height: 13px;
  letter-spacing: 0.1px;
  justify-content: center;

  svg {
    path {
      transition: 0.1s rotate linear;
      transform-origin: center center;
      rotate: ${({ isOpen }) => (isOpen ? '0deg' : '180deg')};
    }
  }
`;

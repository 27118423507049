import {
  GridReadyEvent,
  GridSizeChangedEvent,
  ICellRendererParams,
  RowClassParams
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {
  useGetResourceTotalSumQuery,
  useLazyGetResourceListQuery
} from 'api/calculations';
import { ProjectLabel } from 'components/ProjectLabel';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useCalcId } from 'hooks/useCalcId';
import { useProjectId } from 'hooks/useProjectId';
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { ComplicatedResource } from '../../../../api/calculations/types';
import Progress from '../../../../components/Progress';
import { WrapperAgGrid } from '../Accomplishment/Accomplishment.styles';
import Cell from '../Accomplishment/components/Cell';
import { StyledTooltip } from '../Accomplishment/components/CellEditorSergey/CellEditor.style';
import { ActListContext } from '../CalculationСomplicated';
import { LevelResource, PageStyled } from './Resources.styles';
import { SelectedValues } from './Resources.types';
import { Header } from './components';
import { tableColumns } from './components/Table/Table.service';
import { StyledArrow } from './components/Table/components/Row/Row.styles';
import { getCellValue } from './components/Table/components/Row/components/GroupedRow/GroupedRow.service';
import { CalculationLabel } from '../../../../components/CalculationLabel';
import FunctionsIcon from '@mui/icons-material/Functions';

const fields = ['lsrCode', 'rdStamp', 'documentCode'];

const Resources: React.FC<{ margin?: boolean; basePrice?: boolean }> = ({
  margin = false,
  basePrice = true
}) => {
  const calcID = useCalcId();
  const { calculation } = useContext(ActListContext);
  const projectID = useProjectId();

  const [getResources, { data, isFetching, isSuccess }] =
    useLazyGetResourceListQuery();
  const totalSum = useGetResourceTotalSumQuery(
    { params: { calcID } },
    { skip: !calcID }
  );
  const [selectedValues, setSelectedValues] = useState<SelectedValues>({
    file: [],
    chapter: [],
    position: []
  });
  const [isDirty, setIsDirty] = useState(false);
  const [, setIsReset] = useState(false);

  const Ref = useRef<AgGridReact<ComplicatedResource> | null>(null);
  const [gridRef, setGridRef] = useState<GridReadyEvent<
    ComplicatedResource,
    any
  > | null>(null);

  const [, setTableColumnsLocal] = useState(tableColumns);

  const [hiddenRowsIds, setHiddenRowsIds] = useState<number[]>([]);
  const [filteredData, setFilteredData] = useState<ComplicatedResource[]>([]);

  // const tableData: ComplicatedRowResourceLevel[] = useMemo(() => {
  //   if (!data || isReset) return [];
  //
  //   return data.data.filter((row) => row.rows.length || row.splitRows.length);
  // }, [data, isReset]);

  // const headerWidth = useMemo(
  //   () =>
  //     tableColumnsLocal.reduce((accumulator, current) => {
  //       if (current.field === 'title') {
  //         return current?.width ? accumulator + ' ' + `minmax(${current.width}px,auto)` : accumulator;
  //       } else {
  //         return current?.width ? accumulator + ' ' + current.width + 'px' : accumulator;
  //       }
  //     }, ''),
  //   [tableColumnsLocal],
  // );

  const collapse = (id: number) => {
    // if (setHiddenRowsIds) {
    setHiddenRowsIds((d) => {
      const copy = [...d];
      const addIndex = copy.findIndex((hrId) => hrId === id);
      if (addIndex >= 0) {
        copy.splice(addIndex, 1);
      } else {
        copy.push(id);
      }
      return copy;
    });
    // }
  };
  const isSelectedEmpty =
    selectedValues.file.length === 0 &&
    selectedValues.chapter.length === 0 &&
    selectedValues.position.length === 0;

  const successWithoutFilters = useMemo(
    () => !isFetching && isSuccess && isSelectedEmpty,
    [isFetching, isSuccess]
  );

  // const isResetButtonButton = !successWithoutFilters && !isDirty && tableData.length !== 0;
  const isResetButtonButton = !successWithoutFilters && !isDirty;

  useEffect(() => {
    if (!isFetching) {
      setFilteredData(() => {
        if (data?.data) {
          const indexes: number[] = [];
          const filledArr = data?.data.reduce((acc: any[], curr: any) => {
            const newItem: typeof curr = Object.assign({}, curr);
            let idx = false;
            if (newItem && newItem.parent_id && newItem.id) {
              idx =
                hiddenRowsIds.includes(newItem.parent_id) ||
                indexes.includes(newItem.id);
              if (idx && newItem.children?.length) {
                indexes.push(...newItem.children.map((item: any) => item.id));
              }
            }
            return !idx ? [...acc, newItem] : acc;
          }, []);

          gridRef?.api.hideOverlay();

          // setUpdateLoader(false);
          return filledArr?.length ? filledArr : data.data;
        } else {
          gridRef?.api.hideOverlay();
          // setUpdateLoader(false);
          return [];
        }
      });
    }
  }, [hiddenRowsIds, data?.data, isFetching]);

  useEffect(() => {
    getResources({ params: { calcID }, body: {}, basePrice });
  }, []);

  useEffect(() => {
    if (!data) return;

    setIsDirty(true);
  }, [selectedValues]);

  const onSetFormPositions = () => {
    getResources({
      params: { calcID },
      body: {
        positionFilter:
          selectedValues.position.length === 0
            ? undefined
            : selectedValues.position
      },
      basePrice
    }).then(() => {
      setIsReset(false);
      setIsDirty(false);
      setTableColumnsLocal((prevState) => {
        return prevState.filter((column) => {
          if (!fields.includes(column.field)) {
            return column;
          }
        });
      });
    });
  };

  const onResetFormPositions = () => {
    setSelectedValues({ file: [], chapter: [], position: [] });
    getResources({
      params: { calcID },
      body: {},
      basePrice
    }).then(() => {
      setIsReset(false);
      setIsDirty(false);
      setTableColumnsLocal(tableColumns);
    });
  };
  const getRowClass = (params: RowClassParams<ComplicatedResource>) => {
    // const styles: React.CSSProperties = {};

    const classCell = [];
    if (
      params.node.data?.subType === 'file' ||
      params.node.data?.subType === 'otherFile'
    ) {
      classCell.push('cell-bg-file-resources');
    } else if (params.node.data?.subType === 'level') {
      classCell.push('cell-bg-level-resources');
    } else if (params.node.data?.subType === 'sum') {
      classCell.push('cell-bg-sum-resources');
    } else {
      classCell.push('cell-bg-def-resources');
    }
    return classCell;
  };
  // const checkType
  const columnsDef: any = [
    {
      field: 'index',
      width: 81,
      // maxWidth: 81,
      minWidth: 81,
      headerClass: 'header-complicated',
      suppressMovable: true,
      colSpan: (params: any) => {
        return params?.data?.subType !== 'row' ? 4 : 1;
      },
      headerName: '№',
      cellRenderer: (
        params: ICellRendererParams<ComplicatedResource, any, any>
      ) => {
        return (
          <>
            {params?.data?.subType === 'row' ? (
              params.data.index
            ) : (
              <LevelResource type={params?.data?.subType!}>
                {params?.data?.subType === 'file' && (
                  <StyledArrow
                    onClick={() => collapse(params?.data?.id!)}
                    $open={!hiddenRowsIds.includes(params?.data?.id!)}
                  />
                )}
                {params?.data?.subType === 'sum' && <FunctionsIcon />}
                <StyledTooltip
                  PopperProps={{
                    disablePortal: false
                  }}>
                  <>
                    {params?.data?.id === -1
                      ? 'Суммы по всем сметам'
                      : params?.data?.title}
                  </>
                </StyledTooltip>
              </LevelResource>
            )}
          </>
        );
      }
    },
    {
      field: 'lsrCode',
      hide: !successWithoutFilters,
      width: 250,
      // maxWidth: 250,
      minWidth: 250,
      headerClass: 'header-complicated',

      headerName: 'Шифр ЛСР',
      cellRenderer: (
        params: ICellRendererParams<ComplicatedResource, any, any>
      ) => {
        // return <LevelExecution {...params} collapse={collapse} hiddenArr={hiddenRowsIds} />;
        return <Cell float={'center'}>{params.data?.lsrCode}</Cell>;
      }
    },
    {
      field: 'code',
      width: 100,
      // maxWidth: 100,
      minWidth: 100,
      headerClass: 'header-complicated',
      headerName: 'Код',
      cellRenderer: (
        params: ICellRendererParams<ComplicatedResource, any, any>
      ) => {
        // return <LevelExecution {...params} collapse={collapse} hiddenArr={hiddenRowsIds} />;
        return <Cell float={'center'}>{params.data?.code}</Cell>;
      }
    },
    {
      field: 'title',
      width: 500,
      // maxWidth: 500,
      minWidth: 500,
      headerClass: 'header-complicated',
      headerName: 'Наименование',
      cellRenderer: (
        params: ICellRendererParams<ComplicatedResource, any, any>
      ) => {
        // return <LevelExecution {...params} collapse={collapse} hiddenArr={hiddenRowsIds} />;
        return (
          <Cell isHide={params?.data?.subType !== 'row'}>
            {params.data?.title}
          </Cell>
        );
      }
    },
    {
      field: 'unit',
      width: 100,
      // maxWidth: 100,
      minWidth: 100,
      headerClass: 'header-complicated',
      headerName: 'Ед. изм',
      cellRenderer: (
        params: ICellRendererParams<ComplicatedResource, any, any>
      ) => {
        // return <LevelExecution {...params} collapse={collapse} hiddenArr={hiddenRowsIds} />;
        return <Cell float={'center'}>{params.data?.unit}</Cell>;
      }
    },
    {
      field: 'quantity',
      width: 100,
      // maxWidth: 100,
      minWidth: 100,
      headerClass: 'header-complicated',
      headerName: 'Кол-во',
      cellRenderer: (
        params: ICellRendererParams<ComplicatedResource, any, any>
      ) => {
        // return <LevelExecution {...params} collapse={collapse} hiddenArr={hiddenRowsIds} />;
        // return <Cell float={'center'}>{getCellValue(params.data?.quantity)}</Cell>;
        return (
          <Cell float={'center'}>
            {params.data?.quantity?.toString()?.replace(/\./g, ',')}
          </Cell>
        );
      }
    },
    {
      field: 'level',
      // width: 100,
      // // maxWidth: 100,
      // minWidth: 100,
      headerClass: 'header-complicated',
      headerName: 'Стоимость в базовых ценах',
      // cellRenderer: (params: ICellRendererParams<ComplicatedResource, any, any>) => {
      //   // return <LevelExecution {...params} collapse={collapse} hiddenArr={hiddenRowsIds} />;
      //   return <Cell></Cell>;
      // },
      marryChildren: true,
      children: [
        {
          field: 'baseTotalByUnit',
          width: 200,
          // maxWidth: 200,
          minWidth: 200,
          headerClass: 'header-complicated',
          headerName: 'На единицу',
          cellRenderer: (
            params: ICellRendererParams<ComplicatedResource, any, any>
          ) => {
            // return <LevelExecution {...params} collapse={collapse} hiddenArr={hiddenRowsIds} />;
            return (
              <Cell float={'center'}>
                {getCellValue(params.data?.baseTotalByUnit)}
              </Cell>
            );
          }
        },
        {
          field: 'baseTotal',
          width: 200,
          // maxWidth: 200,
          minWidth: 200,
          headerClass: 'header-complicated',
          headerName: 'Всего',
          cellRenderer: (
            params: ICellRendererParams<ComplicatedResource, any, any>
          ) => {
            // return <LevelExecution {...params} collapse={collapse} hiddenArr={hiddenRowsIds} />;
            return (
              <Cell float={'center'}>
                {getCellValue(params.data?.baseTotal)}
              </Cell>
            );
          }
        }
      ]
    },
    {
      // width: 100,
      // // maxWidth: 100,
      // minWidth: 100,
      headerClass: 'header-complicated',
      headerName: 'Стоимость в текущих ценах',
      // cellRenderer: (params: ICellRendererParams<any, any, any>) => {
      //   // return <LevelExecution {...params} collapse={collapse} hiddenArr={hiddenRowsIds} />;
      //   return <Cell></Cell>;
      // },
      marryChildren: true,
      children: [
        {
          field: 'currTotalByUnit',
          width: 200,
          // maxWidth: 200,
          minWidth: 200,
          headerClass: 'header-complicated',
          headerName: 'На единицу',
          cellRenderer: (
            params: ICellRendererParams<ComplicatedResource, any, any>
          ) => {
            // return <LevelExecution {...params} collapse={collapse} hiddenArr={hiddenRowsIds} />;
            return (
              <Cell float={'center'}>
                {getCellValue(params.data?.currTotalByUnit)}
              </Cell>
            );
          }
        },
        {
          field: 'currTotal',
          width: 200,
          // maxWidth: 200,
          minWidth: 200,
          headerClass: 'header-complicated',
          headerName: 'Всего',
          cellRenderer: (
            params: ICellRendererParams<ComplicatedResource, any, any>
          ) => {
            // return <LevelExecution {...params} collapse={collapse} hiddenArr={hiddenRowsIds} />;
            return (
              <Cell float={'center'}>
                {getCellValue(params.data?.currTotal)}
              </Cell>
            );
          }
        }
      ]
    },
    {
      field: 'weight',
      width: 100,
      // maxWidth: 100,
      minWidth: 100,
      headerClass: 'header-complicated',
      headerName: 'Уд. вес %',
      cellRenderer: (
        params: ICellRendererParams<ComplicatedResource, any, any>
      ) => {
        // return <LevelExecution {...params} collapse={collapse} hiddenArr={hiddenRowsIds} />;
        return (
          <Cell float={'center'}>{getCellValue(params.data?.weight)}</Cell>
        );
      }
    },
    {
      field: 'repeatCount',
      width: 100,
      // maxWidth: 100,
      minWidth: 100,
      headerClass: 'header-complicated',
      headerName: 'Кол-во повторений',
      cellRenderer: (
        params: ICellRendererParams<ComplicatedResource, any, any>
      ) => {
        // return <LevelExecution {...params} collapse={collapse} hiddenArr={hiddenRowsIds} />;
        return <Cell>{params.data?.repeatCount}</Cell>;
      }
    },
    {
      field: 'rdStamp',
      hide: !successWithoutFilters,
      width: 100,
      // maxWidth: 100,
      minWidth: 100,
      headerClass: 'header-complicated',
      headerName: 'Марка РД',
      cellRenderer: (
        params: ICellRendererParams<ComplicatedResource, any, any>
      ) => {
        // return <LevelExecution {...params} collapse={collapse} hiddenArr={hiddenRowsIds} />;
        return <Cell>{params.data?.rdStamp}</Cell>;
      }
    },
    {
      field: 'documentCode',
      hide: !successWithoutFilters,
      width: 250,
      // maxWidth: 250,
      minWidth: 250,
      headerClass: 'header-complicated',
      headerName: 'Шифр документа',
      cellRenderer: (
        params: ICellRendererParams<ComplicatedResource, any, any>
      ) => {
        // return <LevelExecution {...params} collapse={collapse} hiddenArr={hiddenRowsIds} />;
        return <Cell>{params.data?.documentCode}</Cell>;
      }
    }
  ];

  useLayoutEffect(() => {
    if (data?.data) {
      setHiddenRowsIds((d) => {
        const copy = [...d];
        if (!copy.length) {
          data.data
            .filter((item: any) => item.subType === 'file')
            .forEach((file: any) => copy.push(file.id));
        }
        console.log(copy);
        return copy;
      });
    }
  }, [data?.data]);

  useEffect(() => {
    if (isFetching && gridRef) {
      gridRef?.api.showLoadingOverlay();
      setFilteredData([]);
    }
  }, [isFetching, gridRef]);

  useBreadcrumbs(
    [
      { title: <ProjectLabel /> },
      {
        title: 'Расчеты',
        url: `/projects/${projectID}/calculations`
      },
      {
        title: calculation?.title ? (
          <CalculationLabel
            title={calculation?.title}
            type={calculation?.type}
          />
        ) : (
          'Предпросмотр'
        ),
        url: `projects/${projectID}/calculation/${calculation?.id}/edit`
      }
    ],
    [calculation?.title]
  );

  return (
    <PageStyled margin={margin}>
      <Header
        selectedValues={selectedValues}
        isResetButton={isResetButtonButton}
        disabledButton={
          isFetching || (!isDirty && isSelectedEmpty) || totalSum.isFetching
        }
        disableSelect={isFetching || totalSum.isFetching}
        onSetSelectedValues={setSelectedValues}
        onSetFormPositions={onSetFormPositions}
        onResetFormPositions={onResetFormPositions}
      />
      <WrapperAgGrid className="ag-theme-material">
        <AgGridReact
          ref={Ref}
          onGridReady={(e) => setGridRef(e)}
          enableCellTextSelection={true}
          columnDefs={columnsDef}
          defaultColDef={{ resizable: true }}
          groupHeaderHeight={25}
          getRowClass={getRowClass}
          gridOptions={{
            navigateToNextHeader: () => null,
            tabToNextHeader: () => null
          }}
          pinnedTopRowData={data?.totalByRange as any}
          rowData={filteredData}
          suppressCellFocus={true}
          onFirstDataRendered={(event) => {
            event.api.sizeColumnsToFit();
          }}
          onGridSizeChanged={(event: GridSizeChangedEvent<any, any>) => {
            event.api.sizeColumnsToFit();
          }}
          onViewportChanged={(event) => {
            event.api.sizeColumnsToFit();
          }}
          getRowId={(params) => {
            return (
              (params?.data?.subType || '') +
              (params?.data?.id?.toString() || params?.data?.title)
            );
          }}
          // getRowClass={getRowClass}
          getRowHeight={(params) => {
            if (params.node.rowPinned === 'top') {
              return 50;
            }
            return 55;
          }}
          rowStyle={{
            padding: '0 !important'
          }}
          rowHeight={55}
          headerHeight={36}
          loadingOverlayComponent={Progress}
          noRowsOverlayComponent={Progress}></AgGridReact>
      </WrapperAgGrid>
      {/*<TableHeader*/}
      {/*  total={totalSum.data}*/}
      {/*  totalByRange={data?.totalByRange}*/}
      {/*  tableColumns={tableColumnsLocal}*/}
      {/*  headerWidth={headerWidth}*/}
      {/*  isLoading={isFetching}*/}
      {/*  successWithoutFilters={successWithoutFilters}*/}
      {/*/>*/}

      {/*<Table*/}
      {/*  data={tableData}*/}
      {/*  tableColumnsLocal={tableColumnsLocal}*/}
      {/*  loading={isFetching}*/}
      {/*  successWithoutFilters={successWithoutFilters}*/}
      {/*/>*/}
    </PageStyled>
  );
};

export default Resources;

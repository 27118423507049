import { Box, Divider, MenuItem, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { IRowNode } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { enqueueSnackbar } from 'notistack';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { v4 as uuid4 } from 'uuid';
import {
  useEditQuantityMutation,
  useGetExecutionCalculationRimQuery
} from '../../../api/calculations';
import {
  useLazyGetKS2V2Query,
  useLazyGetKS6V2Query
} from '../../../api/params';
import { KSResponse } from '../../../api/params/params.types';
import Progress from '../../../components/Progress';
import {
  ENUMLocalStorage,
  getLocalStorage,
  useLocalStorage
} from '../../../hooks/use-local-storage';
import {
  ActList,
  ActResponse,
  CalcData,
  ExecutionCalculationRimData
} from '../../../types';
import {
  PageStyled,
  WrapperAgGrid
} from '../../Calculations/components/Accomplishment/Accomplishment.styles';
import ActDialog from '../../Calculations/components/Accomplishment/components/ActDialog';
import { Badge } from '../../Calculations/components/Accomplishment/components/CaptionTable/CaptionTable';
import {
  SetupItem,
  SetupRow,
  SetupTable,
  StyledSelect
} from '../../Calculations/components/Accomplishment/components/CaptionTable/CaptionTable.style';
import CustomTableHeader from '../../Calculations/components/Accomplishment/components/CustomTableHeader';
import { StyledParametersButton } from '../../Calculations/components/CalculationBasic/components/CalculationLegend/CalculationLegend.styles';
import {
  ExecutedTabCellEditable,
  formatDateExecutedTab
} from '../../Calculations/components/CalculationDirectory/tabs/ExecutedTab';
import { StepperIndexMethodContext } from '../index-method';
import {
  CellRendererIndexMethod,
  customHeader,
  customHeaderMultiline,
  getNestedChildrenIdsIndexMethodCalculation,
  getRowClassIndexMethodCalculation,
  IIndexMethodCalculationContext
} from '../index-method-calculation';
import { defaultDef, statusesAct } from './index-method-execution.constants';
import { useTable } from './index-method-execution.functions';
import { UpdateParams } from './index-method-execution.types';

const gridOptions = {
  components: {
    cellRenderer: CellRendererIndexMethod,
    headerComponent: CustomTableHeader,
    customHeader,
    customHeaderMultiline,
    ExecutedTabCellEditable
  }
};

export const IndexMethodExecutionTable: FC<{
  actList?: ActResponse;
  updateFragment: number;
  currentAct: ActList | null;
  changeCurrentAct: (act: ActList | null) => void;
  refetchActs: () => void;
  calculation?: CalcData;
  calcID: number;
  firstLoad: boolean;
  toggleFirstLoad: (f?: boolean) => void;
  projectID: number;
}> = (props) => {
  const [update] = useEditQuantityMutation();

  const [getKS2V2, { isFetching: isFetchingKS2V2 }] = useLazyGetKS2V2Query();
  const [getKS6V2, { isFetching: isFetchingKS6V2 }] = useLazyGetKS6V2Query();

  const onUpdate: UpdateParams = async (params, act, calcID) => {
    ref.current?.api.showLoadingOverlay();
    console.log(act, props.calcID, params);
    try {
      if (act && props.calcID) {
        const editedData = {
          actID: act,
          calcID: Number(props.calcID),
          body: {
            rowID: params.data.id,
            quantity:
              params.newValue === undefined ||
              params.newValue === null ||
              params.newValue === '0'
                ? null
                : Number(params.newValue)
          }
        };
        await update(editedData).then(() => props.refetchActs());
      }
    } catch (error) {
      // Обработка ошибки
      console.error('Error:', error);
    }
    return;
  };

  const { setMaxDepth, depth } = useContext(StepperIndexMethodContext);

  const dataFirstFilterTreeLength = useMemo(
    () => props.actList?.actList?.length,
    [props.actList?.actList]
  );
  const [createModal, setCreateModal] = useState(false);

  const renderAct = useMemo(() => {
    if (!props.currentAct) return 'Выберите диапазон';
    return formatDateExecutedTab(props.currentAct);
  }, [props.currentAct]);
  const ref = useRef<AgGridReact<ExecutionCalculationRimData> | null>(null);
  const levelID = useMemo(() => Number(props.calcID || 0), [props.calcID]);
  const { setValue: setLevels, firstValue: firstLevels } = useLocalStorage(
    ENUMLocalStorage.levelsIndexMethodExecute,
    []
  );

  const {
    data: dataCalculationComplicated,
    isFetching: isFetchingCalculationComplicated,
    isLoading: isLoadingCalculationComplicated
  } = useGetExecutionCalculationRimQuery({
    calcID: props.calcID
  });

  const isLoading = useMemo(
    () => isFetchingCalculationComplicated || isLoadingCalculationComplicated,
    [isFetchingCalculationComplicated, isLoadingCalculationComplicated]
  );
  const limitedRows: any[] = useMemo(() => {
    // limit-header
    if (dataCalculationComplicated?.dynamicRows.length) {
      const d: any[] = [
        {
          id: uuid4(),
          type: 'limit-header',
          title: 'лимитированные',
          hasChildren: false,
          including: {
            building: null,
            equipment: null,
            mounting: null,
            other: null
          },
          lvl: 1
        }
      ];
      return d.concat(
        dataCalculationComplicated.dynamicRows.map((el) => ({
          ...el,
          id: uuid4(),
          type: 'limit'
        }))
      );
    }
    return [];
  }, [dataCalculationComplicated?.dynamicRows]);
  // }, []);

  const data: ExecutionCalculationRimData[] = useMemo(() => {
    let lvl = 1;
    const mappedData =
      dataCalculationComplicated?.data.map((e) => {
        if (e.lvl > lvl) {
          lvl = e.lvl;
          if (e.hasChildren) {
            lvl = e.lvl + 1;
          }
        }
        return {
          ...e
        };
      }) ?? [];
    setMaxDepth('indexPage', lvl);

    return mappedData;
  }, [dataCalculationComplicated?.data]);

  // const defaultData: ExecutionCalculationRimData[] = useMemo(() => {
  //   return (
  //     dataCalculationComplicated?.data.map((e) => ({
  //       ...e
  //     })) ?? []
  //   );
  // }, [dataCalculationComplicated?.data]);

  const total = useMemo(() => {
    return dataCalculationComplicated?.total
      ? dataCalculationComplicated?.total
      : [];
  }, [dataCalculationComplicated?.total]);

  // const totalDynamicRows: any[] = useMemo(() => {
  //   return [];
  //   // return total ? (total[0]?.dynamicRows ?? []) : [];
  // }, [total]);

  const [columnDefs, resetColumns] = useTable(
    total[0],
    props.updateFragment,
    props.actList?.actList.length
  );

  // const pinnedTopRowData = useMemo(
  //   () => (columnDefs && columnDefs?.length ? total : undefined),
  //   [columnDefs?.length, total]
  // );
  // const rowData = useMemo(
  //   () => (columnDefs && columnDefs?.length ? data : undefined),
  //   [columnDefs?.length, data]
  // );
  const [collapseRowsIds, setCollapseRowsIds] = useState<number[]>([]);

  const onCollapse = useCallback(
    (ids: number[]) => {
      setCollapseRowsIds((prevState) => {
        const idsToProcess = ids.flatMap((id) => {
          const nestedIds = getNestedChildrenIdsIndexMethodCalculation(
            id,
            dataCalculationComplicated?.data ?? []
          );
          return Array.from(new Set([id, ...nestedIds]));
        });

        const isCollapsing = ids.some((id) => prevState.includes(id));

        if (isCollapsing) {
          // Если элемент уже есть в списке, его дети не исчезают из списка
          return prevState.filter((hrId) => !ids.includes(hrId));
        } else {
          // Если элемент не в списке, добавляем его и его детей в список
          return Array.from(new Set([...prevState, ...idsToProcess]));
        }
      });
    },
    [dataCalculationComplicated?.data]
  );

  const doesExternalFilterPass = useCallback(
    (params: IRowNode<ExecutionCalculationRimData>) => {
      ref.current?.api.setIsExternalFilterPresent(() => false);
      const shouldClose = params.data?.parentID
        ? !collapseRowsIds.includes(params.data?.parentID)
        : true;
      return shouldClose;
    },
    [collapseRowsIds]
  );

  const contextTable = useMemo<IIndexMethodCalculationContext>(
    () => ({
      collapseRowsIds,
      onCollapse,
      current: props.currentAct,
      onUpdate
    }),
    [collapseRowsIds, onCollapse, props.currentAct]
  );

  const download = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const key = e.currentTarget.dataset?.key;
      const getKS = key === 'ks-2' ? getKS2V2 : getKS6V2;
      if (props.calcID && props.currentAct && key && props.currentAct.id) {
        enqueueSnackbar(
          'Ожидайте загрузку архива, около 2 минут. Не покидайте вкладку.',
          {
            autoHideDuration: 10000,
            variant: 'info'
          }
        );
        getKS({ calcID: Number(props.calcID), actID: props.currentAct.id })
          .then((response) => {
            const res = { data: response.data! as KSResponse };
            enqueueSnackbar('Загрузка завершена', {
              autoHideDuration: 2000,
              variant: 'success'
            });
            const sliceSize = 1024;
            const byteCharacters = atob(res?.data?.archiveBase64 as string);
            const byteArrays = [];

            for (
              let offset = 0;
              offset < byteCharacters.length;
              offset += sliceSize
            ) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);
              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }
            const blob = new Blob(byteArrays, { type: 'application/zip' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${key === 'ks-2' ? 'КС-2' : 'КС-6A'}.zip`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch((er) => {
            console.log(er);
            enqueueSnackbar(
              'При загрузке архива произошла ошибка, попробуйте позже',
              {
                autoHideDuration: 2000,
                variant: 'error'
              }
            );
          });
      }
    },
    [props.calcID, props.currentAct, getKS2V2, getKS6V2]
  );

  // useEffect(() => {
  //   if (isFetchingCalculationComplicated) {
  //     // setColumnDefs(undefined);
  //     props.toggleFirstLoad(true);
  //   }
  // }, [isFetchingCalculationComplicated]);

  useEffect(() => {
    if (ref.current && ref.current.api) {
      if (isLoading) {
        ref.current.api.showLoadingOverlay();
      } else {
        ref.current.api.hideOverlay();
      }
    }
  }, [isLoading, contextTable]);
  useEffect(() => {
    if (ref.current && ref.current.api) {
      ref.current.api.refreshCells({ force: true });
      ref.current.api.refreshHeader();
    }
  }, [contextTable]);

  useEffect(() => {
    if (ref.current && ref.current.api) {
      ref.current.api.setIsExternalFilterPresent(() => true);
      ref.current.api.onFilterChanged();
      ref.current.api.refreshCells({ force: true });
    }
  }, [doesExternalFilterPass]);

  useEffect(() => {
    if (collapseRowsIds && !props.firstLoad) {
      setLevels((prevState) => {
        const isFind = prevState?.find((level) => level.id === levelID);
        if (isFind) {
          return prevState?.map((level) => {
            if (level.id === levelID) {
              return {
                id: levelID,
                levels: collapseRowsIds
              };
            }
            return level;
          });
        } else {
          return [
            ...(prevState ?? []),
            {
              id: levelID,
              levels: collapseRowsIds
            }
          ];
        }
      });
    }
  }, [collapseRowsIds, levelID, setLevels]);

  useEffect(() => {
    if (data.length) {
      const levels = getLocalStorage(ENUMLocalStorage.levelsIndexMethodExecute);
      const isFind = (levels ?? []).find((level) => level.id === levelID);
      if (isFind) {
        setCollapseRowsIds(isFind.levels);
        props.toggleFirstLoad(false);
      } else {
        if (data && data.length) {
          const firstElements = data
            .map((item) => (item.parentID === null ? item.id : null))
            .filter((item): item is number => item !== null);
          if (firstElements && firstElements.length) {
            onCollapse(firstElements);
          }
          props.toggleFirstLoad(false);
        }
      }
    }
  }, [data, levelID, props.calcID]);

  useEffect(() => {
    if (!props.firstLoad) {
      const hideRows =
        data
          ?.filter((el) => {
            return el.lvl && el.lvl >= depth.indexPage;
          })
          ?.map((_) => _.id! as number) ?? [];
      setCollapseRowsIds(hideRows);
      ref.current?.api?.setIsExternalFilterPresent(() => true);
      ref.current?.api?.onFilterChanged();
    }
  }, [depth.indexPage]);

  useEffect(() => {
    resetColumns();
  }, [props.actList]);

  return (
    <PageStyled style={{ height: 'calc(100% - 10px)' }}>
      <Box sx={{ background: 'white' }}>
        <SetupTable $border>
          <SetupRow>
            <SetupItem hideBefore>
              Акт:
              {!dataFirstFilterTreeLength ? (
                <StyledParametersButton
                  onClick={() => setCreateModal(true)}
                  fullWidth
                  sx={{ fontSize: '14px' }}
                  variant={'outlined'}>
                  Создать новый акт
                </StyledParametersButton>
              ) : (
                <StyledSelect
                  width={235}
                  onClear={() => {}}
                  nullableValues={[renderAct, 'Выберите диапазон']}
                  SelectProps={{
                    renderValue: () => renderAct,
                    MenuProps: {
                      sx: {
                        maxHeight: 500,
                        width: 235
                      }
                    },
                    inputProps: {
                      sx: {
                        padding: '4.5px 14px'
                      }
                    },
                    value: renderAct || 'Выберите диапазон',
                    placeholder: 'Выберите диапазон'
                  }}
                  value={renderAct || 'Выберите диапазон'}
                  placeholder={'Выберите диапазон'}
                  fullWidth
                  select>
                  <Stack direction="row" px={1} py={1}>
                    <StyledParametersButton
                      onClick={() => setCreateModal(true)}
                      fullWidth
                      sx={{ fontSize: '14px' }}
                      variant={'outlined'}>
                      Создать новый акт
                    </StyledParametersButton>
                  </Stack>
                  <Divider />
                  <MenuItem
                    style={{ height: 0, overflow: 'hidden', padding: 0 }}
                    selected
                    hidden={true}
                    value={'Выберите диапазон'}>
                    Выберите диапазон
                  </MenuItem>
                  {(props.actList?.actList || [])?.map((item) => {
                    return (
                      <MenuItem
                        key={item.id}
                        onClick={() => {
                          if (props.currentAct?.id !== item.id) {
                            props.changeCurrentAct(item);
                          }
                        }}
                        selected={
                          formatDateExecutedTab(item) === renderAct &&
                          item.id === props.currentAct?.id
                        }
                        value={formatDateExecutedTab(item)}>
                        {formatDateExecutedTab(item)}
                      </MenuItem>
                    );
                  })}
                </StyledSelect>
              )}
            </SetupItem>
            <SetupItem>
              Экспорт:
              <StyledParametersButton
                data-key={'ks-2'}
                disabled={isFetchingKS2V2 || !props.currentAct}
                fullWidth
                sx={{ fontSize: '14px' }}
                onClick={download}
                variant={'outlined'}>
                {isFetchingKS2V2 ? <CircularProgress size={20} /> : 'КС-2'}
              </StyledParametersButton>
              <StyledParametersButton
                data-key={'ks-6'}
                onClick={download}
                disabled={isFetchingKS6V2 || !props.currentAct}
                fullWidth
                sx={{ fontSize: '14px' }}
                variant={'outlined'}>
                {isFetchingKS6V2 ? <CircularProgress size={20} /> : 'КС-6а'}
              </StyledParametersButton>
            </SetupItem>
          </SetupRow>
          <SetupRow>
            {props.currentAct && props.currentAct.status && (
              <Badge {...statusesAct[props.currentAct.status || 'NEW']} />
            )}
          </SetupRow>
        </SetupTable>
      </Box>
      <WrapperAgGrid className="ag-theme-material reference-prices index-methods-calculation">
        <AgGridReact
          ref={ref}
          context={contextTable}
          defaultColDef={defaultDef}
          columnDefs={columnDefs}
          singleClickEdit
          getRowClass={(props) =>
            getRowClassIndexMethodCalculation(props as any)
          }
          gridOptions={gridOptions}
          pinnedTopRowData={dataCalculationComplicated?.total}
          rowData={
            props.firstLoad
              ? undefined
              : dataCalculationComplicated?.data?.concat(limitedRows)
          }
          getRowId={(params) => (params.data.id || '').toString()}
          getRowHeight={(params) => {
            if (params.data?.type === 'limit-header') {
              return 30;
            }
            if (params.node.rowPinned === 'top') {
              return 50;
            }
            return 55;
          }}
          rowStyle={{
            padding: '0 !important'
          }}
          getRowStyle={(params) => {
            if (params.data?.type === 'limit-header') {
              return {
                'pointer-events': 'none'
              };
            }
            return;
          }}
          suppressCellFocus
          enableCellTextSelection
          ensureDomOrder
          maintainColumnOrder
          rowHeight={55}
          groupHeaderHeight={40}
          headerHeight={40}
          loadingOverlayComponent={Progress}
          noRowsOverlayComponent={Progress}
          // onFirstDataRendered={(event) => {
          //   event.api.sizeColumnsToFit();
          // }}
          // onGridSizeChanged={(event) => {
          //   event.api.sizeColumnsToFit();
          // }}
          // onViewportChanged={(event) => {
          //   event.api.sizeColumnsToFit();
          // }}
          doesExternalFilterPass={doesExternalFilterPass}
        />
        <ActDialog
          data={props.actList ?? undefined}
          update={() => {
            ref?.current?.api?.showLoadingOverlay();
            props.toggleFirstLoad(true);
            resetColumns();
          }}
          setCurrentOuter={props.changeCurrentAct}
          open={createModal}
          close={() => setCreateModal(false)}
        />
      </WrapperAgGrid>
    </PageStyled>
  );
};

import { Button, Dialog, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {
  useGetRimCoefficientsQuery,
  useUpdateRimCoefficientsMutation
} from 'api/params';
import { FormikProvider, useFormik } from 'formik';
import { useCalcId } from 'hooks/useCalcId';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import useSearchParam from 'hooks/useSearchParams';
import { enqueueSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { Table } from '.';
import { IndexMethodSearchParamsTab } from '../../../index-method.types';
import { formikSetup, setupDialogStyle } from './parameters-dialog.constants';
import { ParametersDialogTabs } from './parameters-dialog.tabs';
import {
  DialogForm,
  TParametersDialogProps,
  TParametersTabs
} from './parameters-dialog.types';
import { BtnWrapper, Header, Wrapper } from './parameters-dilog.style';
import { modifyRequest, modifyResponse } from './table/table.helper';

export const ParametersDialog: React.FC<TParametersDialogProps> = ({
  onClose,
  open,
  updateFragment
}) => {
  const [tab, setTab] = React.useState<TParametersTabs>('limited_costs');

  const [calcInfo] = useSearchParam<IndexMethodSearchParamsTab>({
    key: 'tab'
  });

  const isExecuted = useMemo(() => {
    return calcInfo === 'execution';
  }, [calcInfo]);

  const calcID = useCalcId();

  const { data: responseData } = useGetRimCoefficientsQuery({ calcID });

  const [updateFunc, requestInfo] = useUpdateRimCoefficientsMutation();

  const { ConfirmDialog, openConfirm } = useConfirmDialog({
    handleConfirm: (confirm, fn) => {
      if (confirm) {
        fn?.();
      }
    }
  });
  // for (const key in exampleDataFromServer) {
  const data = React.useMemo(() => {
    return {
      rows: modifyResponse(responseData),
      removed: []
    };
  }, [responseData]);

  const formik = useFormik<any>({
    ...formikSetup,
    initialValues: data as DialogForm,
    onSubmit: (values: DialogForm) => {
      const requestData = {
        limitedCosts: [
          ...modifyRequest(values.rows),
          ...modifyRequest(values.removed)
        ],
        calcID
      };
      updateFunc(requestData).then(updateFragment);
    }
  });

  const onChange = (
    _: React.SyntheticEvent<Element, Event>,
    t: TParametersTabs
  ) => {
    setTab(t);
  };
  const handleClose = React.useCallback(() => {
    const errors = Object.values(formik.errors)?.length;
    if (errors || formik.dirty) {
      openConfirm(() => {
        onClose();
      });
      return;
    }
    onClose();
  }, [formik.errors, onClose, openConfirm]);

  React.useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open]);
  React.useEffect(() => {
    if (data) {
      formik.resetForm(data as any);
    }
  }, [data]);

  useMutationHandlers(requestInfo, () => {
    enqueueSnackbar('Параметры расчета сметы успешно изменены', {
      variant: 'success'
    });
  });

  return (
    <Dialog
      {...setupDialogStyle}
      open={open}
      onClose={(ev, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}>
      <FormikProvider value={formik}>
        <Wrapper>
          <Header>
            <Typography variant="h6" fontSize={'16px'}>
              Изменение параметров расчета смет
            </Typography>
            <BtnWrapper>
              <Button
                disabled={
                  (formik.errors?.rows &&
                    !!Object.values(formik.errors.rows).filter((el) => !!el)
                      .length) ||
                  !formik.isValid ||
                  !formik.dirty ||
                  requestInfo.isLoading
                }
                onClick={() => {
                  formik.handleSubmit();
                }}
                color="success">
                {requestInfo.isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  'Применить'
                )}
              </Button>
              <Button
                disabled={requestInfo.isLoading}
                color="primary"
                onClick={handleClose}>
                Закрыть
              </Button>
            </BtnWrapper>
          </Header>
          <ParametersDialogTabs onChange={onChange} tab={tab} />
          <Table estimates={responseData?.estimates} />
        </Wrapper>
        <ConfirmDialog />
      </FormikProvider>
    </Dialog>
  );
};

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ClickAwayListener, Popper, Stack, Typography } from '@mui/material';
import { useGetCalculationListQuery } from 'api/calculations';
import { useProjectId } from 'hooks/useProjectId';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FileTypeChip } from '../../pages/Calculations/components/FileTypeChip';
import { CalcDataType } from '../../types';
import Tooltip from '../Tooltip';
import { useCalcId } from '../../hooks/useCalcId';

export const CalculationLabel: React.FC<{
  title: any;
  type?: CalcDataType;
}> = ({ type, title }) => {
  const listRef = React.useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const projectID = useProjectId();
  const calcID = useCalcId();
  const location = useLocation();

  const {
    data: calcList,
    isLoading,
    isFetching,
    isSuccess
  } = useGetCalculationListQuery({
    projectID: Number(projectID),
    filters: null,
    limit: 999,
    offset: 0
  });
  React.useEffect(() => {
    setOpen(false);
    // window.open(location.pathname, '_self');
  }, [location.pathname]);
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div
        ref={listRef}
        style={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          cursor: calcList?.data.length ? 'pointer' : 'default'
        }}
        onClick={() => calcList?.data.length && setOpen(true)}>
        <Tooltip title={title}>
          <div
            style={{
              maxWidth: '450px',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
            {title ?? ''}
          </div>
        </Tooltip>

        {type && <FileTypeChip type={type} />}
        <ArrowDropDownIcon
          sx={{
            transition: 'all linear 0.2s',
            rotate: open ? '0' : '180deg'
          }}
        />
        <Popper
          placement="bottom-start"
          sx={{
            zIndex: 9,
            background: 'white',
            padding: '10px 0',
            boxShadow: (theme) => theme.shadows[10],
            borderRadius: 1.5
          }}
          open={open}
          anchorEl={listRef.current}>
          <Stack
            direction="column"
            minWidth={'300px'}
            maxWidth={'700px'}
            maxHeight={'180px'}
            sx={{ overflowX: 'hidden', overflowY: 'auto' }}
            textOverflow={'ellipsis'}>
            {calcList?.data.map((calc) => {
              return (
                <Stack
                  key={calc.id}
                  padding={'6px 16px'}
                  display={'inline-flex'}
                  component={calc.id == calcID ? 'span' : 'a'}
                  href={
                    calc.type === 'resourceIndex'
                      ? `/projects/${projectID}/calculation/${calc.id}/index-method?mode=editor&type=${calc.type}?tab=calculation`
                      : `/projects/${projectID}/calculation/${calc.id}/edit`
                  }
                  direction="row"
                  sx={
                    calc.id == calcID
                      ? {
                          cursor: 'default',
                          background: '#1976D214',
                          '& p': { color: '#0044B4' }
                        }
                      : { cursor: 'pointer' }
                  }
                  spacing={1.2}>
                  <Tooltip title={calc.title}>
                    <Typography
                      sx={{
                        overflowX: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                      }}>
                      {calc.title}
                    </Typography>
                  </Tooltip>
                  <FileTypeChip
                    sx={{ justifySelf: 'start', fontSize: '10px' }}
                    type={calc.type}
                  />
                </Stack>
              );
            })}
          </Stack>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

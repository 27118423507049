import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  ClickAwayListener,
  Popper,
  Stack,
  Typography
} from '@mui/material';
import {
  useGetProjectDashboardQuery,
  useGetShortProjectsQuery
} from 'api/projects';
import Tooltip from 'components/Tooltip';
import { useProjectId } from 'hooks/useProjectId';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export const ProjectLabel = () => {
  const projectID = useProjectId();
  const { data: project } = useGetProjectDashboardQuery(
    { projectID },
    { skip: !projectID }
  );

  const location = useLocation();

  const listRef = React.useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = React.useState(false);
  // const projectID = useProjectId();

  const {
    data: projectList,
    isLoading,
    isFetching,
    isSuccess
  } = useGetShortProjectsQuery();

  React.useEffect(() => {
    setOpen(false);
  }, [location.pathname]);
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box
        sx={{
          display: 'flex',
          gap: '0px',
          alignItems: 'center',
          cursor: projectList?.projects.length ? 'pointer' : 'default',
          '&:hover': {
            textDecoration: 'underline'
          }
        }}
        ref={listRef}
        onClick={() =>
          projectList?.projects.length && setOpen((prevState) => !prevState)
        }>
        <Tooltip title={project?.project.shortName}>
          <div
            style={{
              maxWidth: '450px',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
            {project?.project.shortName ?? ''}
          </div>
        </Tooltip>
        <ArrowDropDownIcon
          sx={{
            transition: 'all linear 0.2s',
            rotate: open ? '0' : '180deg'
          }}
        />
        {/* {type && <FileTypeChip type={type} />} */}
        <Popper
          placement="bottom-start"
          sx={{
            zIndex: 9,
            background: 'white',
            padding: '10px 0',
            boxShadow: (theme) => theme.shadows[10],
            borderRadius: 1.5
          }}
          open={open}
          anchorEl={listRef.current}>
          <Stack
            direction="column"
            minWidth={'200px'}
            maxWidth={'700px'}
            maxHeight={'180px'}
            sx={{ overflowX: 'hidden', overflowY: 'auto' }}
            textOverflow={'ellipsis'}>
            {projectList?.projects.map((p) => {
              return (
                <Stack
                  key={p.id}
                  padding={'6px 16px'}
                  display={'inline-flex'}
                  component={p.id == projectID ? 'span' : Link}
                  to={`/dashboard/${p.id}`}
                  direction="row"
                  sx={
                    p.id == projectID
                      ? {
                          cursor: 'default',
                          background: '#1976D214',
                          '& p': { color: '#0044B4' }
                        }
                      : { cursor: 'pointer' }
                  }
                  spacing={1.2}>
                  <Tooltip title={p.shortName}>
                    <Typography
                      sx={{
                        overflowX: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                      }}>
                      {p.shortName}
                    </Typography>
                  </Tooltip>
                </Stack>
              );
            })}
          </Stack>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

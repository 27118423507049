import {
  ColDef,
  ColGroupDef,
  NewValueParams,
  ValueGetterParams,
  ValueSetterParams
} from 'ag-grid-community';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  ExecutionCalculationRimData,
  HandbookExecutionRowPartData,
  HandbookExecutionTreeData,
  PartExecutionRim
} from 'types';
import { generateNameHeader } from '../../Calculations/components/Accomplishment/helper';

function generateColumnsParts(
  parts: PartExecutionRim[]
): (
  | ColDef<ExecutionCalculationRimData>
  | ColGroupDef<ExecutionCalculationRimData>
)[] {
  return (
    parts?.reduce(
      (
        acc: (
          | ColDef<ExecutionCalculationRimData>
          | ColGroupDef<ExecutionCalculationRimData>
        )[],
        part,
        idx
      ) => {
        let headerName = '';
        let ichActHHeader = false;
        switch (part?.type) {
          case 'total':
            headerName = 'Всего';
            break;
          case 'executed':
            headerName = 'Выполнено';
            break;
          case 'rest':
            headerName = 'Остаток';
            break;
          default:
            ichActHHeader = true;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            headerName = generateNameHeader(part.startDate!, part.endDate!);
            break;
        }

        acc.push({
          field: 'parts',
          headerName,
          headerClass: (params: any) =>
            part.actID === params?.context?.current?.id
              ? 'header-top-border'
              : '',
          headerGroupComponent: 'headerComponent',
          // suppressMovable: false,
          marryChildren: true,
          lockPinned: true,
          suppressMovable: true,
          children:
            headerName !== 'Всего'
              ? ([
                  {
                    field: `parts.${idx}.quantity.total`,
                    headerName: 'Кол-во',
                    cellClass: (params: any) =>
                      part.actID === params?.context?.current?.id
                        ? 'header-left-border'
                        : '',
                    headerClass: (params: any) =>
                      part.actID === params?.context?.current?.id
                        ? 'header-left-border'
                        : '',
                    // columnGroupShow: 'closed',
                    width: 120,
                    minWidth: 120,
                    lockPinned: true,
                    cellRendererParams: {
                      decimalMax: 6,
                      idx
                    },
                    cellRenderer: 'cellRenderer',
                    onCellValueChanged: (
                      params: NewValueParams<
                        HandbookExecutionTreeData,
                        HandbookExecutionRowPartData['quantity']
                      >
                    ) => {
                      params?.context?.onUpdate(
                        params,
                        part?.actID,
                        params.data.id
                      );
                    },
                    valueGetter: (
                      params: ValueGetterParams<
                        ExecutionCalculationRimData,
                        any
                      >
                    ) => {
                      return params?.data?.parts?.[idx]?.quantity.total;
                    },
                    valueSetter: (
                      params: ValueSetterParams<
                        ExecutionCalculationRimData,
                        any
                      >
                    ) => {
                      const updatedData = {
                        ...params.data,
                        parts: params.data.parts.map((e) =>
                          e.actID === part.actID
                            ? {
                                ...e,
                                quantity: {
                                  ...e.quantity,
                                  total: params.newValue
                                }
                              }
                            : e
                        )
                      };
                      params.api.applyTransaction({ update: [updatedData] });
                      return true;
                    },
                    editable: ({ data, context }: any) => {
                      return ichActHHeader
                        ? data?.type === 'level' &&
                            context?.current?.id === part?.actID
                        : false;
                    }
                  },
                  {
                    field:
                      `parts.${idx}.${part.dynamicRows.length ? 'total' : 'cost.total'}` as keyof ExecutionCalculationRimData,
                    headerName: part.dynamicRows.length
                      ? 'Всего стоимость'
                      : `Всего в текущем уровне цен`,
                    cellClass: (params: any) =>
                      part.actID === params?.context?.current?.id
                        ? 'header-right-border'
                        : '',
                    headerClass: (params: any) =>
                      part.actID === params?.context?.current?.id
                        ? 'header-right-border'
                        : '',
                    columnGroupShow: 'closed',
                    width: 136,
                    minWidth: 136,
                    lockPinned: true,
                    cellRendererParams: {
                      decimalMax: 2,
                      idx
                    },
                    cellRenderer: 'cellRenderer'
                  },
                  // {
                  //   field: `parts.${idx}.quantity`,
                  //   headerName: 'Кол-во',
                  //   headerClass: (params: any) =>
                  //     part.actID === params?.context?.current?.id
                  //       ? 'header-left-border'
                  //       : '',
                  //   columnGroupShow: 'open',
                  //   children: [
                  //     {
                  //       field: `parts.${idx}.quantity.perUnit`,
                  //       headerName: 'на ед. изм.',
                  //       cellClass: (params: any) =>
                  //         part.actID === params?.context?.current?.id
                  //           ? 'header-left-border'
                  //           : '',
                  //       headerClass: (params: any) =>
                  //         part.actID === params?.context?.current?.id
                  //           ? 'header-left-border'
                  //           : '',
                  //       cellRendererParams: {
                  //         decimalMax: 6
                  //       },
                  //       cellRenderer: 'cellRenderer',
                  //       width: 100
                  //     },
                  //     {
                  //       field: `parts.${idx}.quantity.coefficient`,
                  //       headerName: 'коэффициенты',
                  //       cellRendererParams: {
                  //         decimalMax: 6
                  //       },
                  //       cellRenderer: 'cellRenderer',
                  //       width: 100
                  //     },
                  //     {
                  //       field: `parts.${idx}.quantity.total`,
                  //       headerName: 'всего с учетом к-т',
                  //       cellRendererParams: {
                  //         decimalMax: 6
                  //       },
                  //       cellRenderer: 'cellRenderer',
                  //       width: 118,
                  //       onCellValueChanged: (
                  //         params: NewValueParams<
                  //           HandbookExecutionTreeData,
                  //           HandbookExecutionRowPartData['quantity']
                  //         >
                  //       ) => {
                  //         console.log(params);
                  //         params?.context?.onUpdate(
                  //           params,
                  //           part?.actID,
                  //           params.data.id
                  //         );
                  //       },
                  //       valueGetter: (
                  //         params: ValueGetterParams<
                  //           ExecutionCalculationRimData,
                  //           any
                  //         >
                  //       ) => {
                  //         return params?.data?.parts?.[idx]?.quantity.total;
                  //       },
                  //       valueSetter: (
                  //         params: ValueSetterParams<
                  //           ExecutionCalculationRimData,
                  //           any
                  //         >
                  //       ) => {
                  //         const updatedData = {
                  //           ...params.data,
                  //           parts: params.data.parts.map((e) =>
                  //             e.actID === part.actID
                  //               ? {
                  //                   ...e,
                  //                   quantity: {
                  //                     ...e.quantity,
                  //                     total: params.newValue
                  //                   }
                  //                 }
                  //               : e
                  //           )
                  //         };
                  //         params.api.applyTransaction({
                  //           update: [updatedData]
                  //         });
                  //         return true;
                  //       },
                  //       editable: ({ data, context }: any) => {
                  //         return ichActHHeader
                  //           ? data?.type === 'level' &&
                  //               context?.current?.id === part?.actID
                  //           : false;
                  //       }
                  //     }
                  //   ]
                  // },
                  {
                    headerName: 'Сметная стоимость, руб.',
                    columnGroupShow: 'open',
                    marryChildren: true,
                    lockPinned: true,
                    children: [
                      {
                        field: `parts.${idx}.cost.basis.perUnit`,
                        headerName:
                          'на единицу измерения в базисном уровне цен',
                        cellRendererParams: {
                          decimalMax: 2,
                          idx
                        },
                        lockPinned: true,
                        cellRenderer: 'cellRenderer',
                        width: 160,
                        minWidth: 160
                      },
                      {
                        field: `parts.${idx}.cost.basis.multiplier`,
                        headerName: 'индекс',
                        cellRendererParams: {
                          decimalMax: 2,
                          idx
                        },
                        lockPinned: true,
                        cellRenderer: 'cellRenderer',
                        width: 100,
                        minWidth: 100
                      },
                      {
                        field: `parts.${idx}.cost.current.perUnit`,
                        headerName: 'на единицу измерения в текущем уровне цен',
                        cellRendererParams: {
                          decimalMax: 2,
                          idx
                        },
                        lockPinned: true,
                        cellRenderer: 'cellRenderer',
                        width: 136,
                        minWidth: 136
                      },
                      {
                        field: `parts.${idx}.cost.current.multiplier`,
                        headerName: 'коэффициенты',
                        lockPinned: true,
                        cellRendererParams: {
                          decimalMax: 2,
                          idx
                        },
                        cellRenderer: 'cellRenderer',
                        width: 103,
                        minWidth: 103
                      },
                      {
                        field: `parts.${idx}.cost.total`,
                        headerName: `${part?.dynamicRows.length ? 'итого' : 'всего'} в текущем уровне цен`,
                        cellRendererParams: {
                          decimalMax: 2,
                          idx
                        },
                        lockPinned: true,
                        cellRenderer: 'cellRenderer',
                        width: 160,
                        minWidth: 160
                      }
                    ]
                  },
                  ...(part?.dynamicRows.length
                    ? part.dynamicRows
                        .map((row, idxD) => ({
                          field:
                            `parts.${idx}.dynamicRows.${idxD}.value` as keyof ExecutionCalculationRimData,
                          columnGroupShow: 'open',
                          // width: 158,
                          // maxWidth: 158,
                          headerComponent: 'customHeaderMultiline',
                          // width: 158,
                          headerName: `${row.title ?? ''}`,
                          lockPinned: true,
                          cellRenderer: 'cellRenderer',
                          width: 136,
                          minWidth: 136,
                          cellRendererParams: {
                            float: 'right',
                            decimalMax: 6,
                            idx
                          }
                        }))
                        .concat([
                          {
                            field:
                              `parts.${idx}.total` as keyof ExecutionCalculationRimData,
                            headerName: 'Всего стоимость',
                            columnGroupShow: 'open',
                            cellRendererParams: {
                              decimalMax: 6,
                              idx
                            },
                            lockPinned: true,
                            cellRenderer: 'cellRenderer',
                            width: 160,
                            minWidth: 160
                          } as any
                        ])
                    : []),
                  {
                    field: `parts.${idx}.tz`,
                    headerName: 'ТЗ',
                    columnGroupShow: 'open',
                    cellRendererParams: {
                      decimalMax: 2,
                      idx
                    },
                    lockPinned: true,
                    cellRenderer: 'cellRenderer',
                    width: 136,
                    minWidth: 136
                  },
                  {
                    field: `parts.${idx}.tzm`,
                    headerName: 'ТЗМ',
                    cellClass: (params: any) =>
                      part.actID === params?.context?.current?.id
                        ? 'header-right-border'
                        : '',
                    headerClass: (params: any) =>
                      part.actID === params?.context?.current?.id
                        ? 'header-right-border'
                        : '',
                    columnGroupShow: 'open',
                    cellRendererParams: {
                      decimalMax: 2,
                      idx
                    },
                    lockPinned: true,
                    cellRenderer: 'cellRenderer',
                    width: 136,
                    minWidth: 136
                  }
                ] as any)
              : ([
                  {
                    field: `parts.${idx}.quantity.total`,
                    headerName: 'Кол-во',
                    columnGroupShow: 'closed',
                    width: 120,
                    minWidth: 120,
                    cellRendererParams: {
                      decimalMax: 6,
                      idx
                    },
                    lockPinned: true,
                    cellRenderer: 'cellRenderer',
                    onCellValueChanged: (
                      params: NewValueParams<ExecutionCalculationRimData, any>
                    ) => {
                      console.log(params);
                      params?.context?.onUpdate(
                        params,
                        part?.actID,
                        params.data.id
                      );
                    },
                    valueGetter: (
                      params: ValueGetterParams<
                        ExecutionCalculationRimData,
                        any
                      >
                    ) => {
                      return params?.data?.parts?.[idx]?.quantity.total;
                    },
                    valueSetter: (
                      params: ValueSetterParams<
                        ExecutionCalculationRimData,
                        any
                      >
                    ) => {
                      const updatedData = {
                        ...params.data,
                        parts: params.data.parts.map((e) =>
                          e.actID === part.actID
                            ? {
                                ...e,
                                quantity: {
                                  ...e.quantity,
                                  total: params.newValue
                                }
                              }
                            : e
                        )
                      };
                      params.api.applyTransaction({ update: [updatedData] });
                      return true;
                    },
                    editable: ({ data, context }: any) => {
                      // return;
                      // data?.type === 'RATE';
                      return ichActHHeader
                        ? data?.type === 'level' &&
                            context?.current?.id === part?.actID
                        : false;
                    }
                  },
                  {
                    field:
                      `parts.${idx}.${part.dynamicRows.length ? 'total' : 'cost.total'}` as keyof ExecutionCalculationRimData,
                    headerName: part.dynamicRows.length
                      ? 'Всего стоимость'
                      : `Всего в текущем уровне цен`,
                    columnGroupShow: 'closed',
                    width: 136,
                    minWidth: 136,
                    cellRendererParams: {
                      decimalMax: 6,
                      idx
                    },
                    lockPinned: true,
                    cellRenderer: 'cellRenderer'
                  },
                  {
                    headerName: 'Кол-во',
                    columnGroupShow: 'open',
                    marryChildren: true,
                    lockPinned: true,
                    children: [
                      {
                        field: 'quantity.perUnit',
                        headerName: 'на ед. изм.',
                        cellRendererParams: {
                          decimalMax: 6,
                          idx
                        },
                        lockPinned: true,
                        cellRenderer: 'cellRenderer',
                        width: 100,
                        minWidth: 100
                      },
                      {
                        field: 'quantity.coeff',
                        headerName: 'коэффициенты',
                        cellRendererParams: {
                          decimalMax: 6,
                          idx
                        },
                        lockPinned: true,
                        cellRenderer: 'cellRenderer',
                        width: 100,
                        minWidth: 100
                      },
                      {
                        field: 'quantity.totalCoeff',
                        headerName: 'всего с учетом к-т',
                        cellRendererParams: {
                          decimalMax: 6,
                          idx
                        },
                        lockPinned: true,
                        cellRenderer: 'cellRenderer',
                        width: 136,
                        minWidth: 136,
                        onCellValueChanged: (
                          params: NewValueParams<
                            HandbookExecutionTreeData,
                            HandbookExecutionRowPartData['quantity']
                          >
                        ) => {
                          console.log(params);
                          params?.context?.onUpdate(
                            params,
                            part?.actID,
                            params.data.id
                          );
                        },
                        valueGetter: (
                          params: ValueGetterParams<
                            ExecutionCalculationRimData,
                            any
                          >
                        ) => {
                          return params?.data?.parts?.[idx]?.quantity.total;
                        },
                        valueSetter: (
                          params: ValueSetterParams<
                            ExecutionCalculationRimData,
                            any
                          >
                        ) => {
                          const updatedData = {
                            ...params.data,
                            parts: params.data.parts.map((e) =>
                              e.actID === part.actID
                                ? {
                                    ...e,
                                    quantity: {
                                      ...e.quantity,
                                      total: params.newValue
                                    }
                                  }
                                : e
                            )
                          };
                          params.api.applyTransaction({
                            update: [updatedData]
                          });
                          return true;
                        },
                        editable: ({ data, context }: any) => {
                          // return data?.type === 'RATE';
                          return ichActHHeader
                            ? data?.type === 'level' &&
                                context?.current?.id === part?.actID
                            : false;
                        }
                      }
                    ]
                  },
                  {
                    headerName: 'Сметная стоимость, руб.',
                    columnGroupShow: 'open',
                    lockPinned: true,
                    marryChildren: true,
                    children: [
                      {
                        field: `parts.${idx}.cost.basis.perUnit`,
                        headerName:
                          'на единицу измерения в базисном уровне цен',
                        cellRendererParams: {
                          decimalMax: 6,
                          idx
                        },
                        lockPinned: true,
                        cellRenderer: 'cellRenderer',
                        width: 160,
                        minWidth: 160
                      },
                      {
                        field: `parts.${idx}.cost.basis.multiplier`,
                        headerName: 'индекс',
                        cellRendererParams: {
                          decimalMax: 6,
                          idx
                        },
                        lockPinned: true,
                        cellRenderer: 'cellRenderer',
                        width: 100,
                        minWidth: 100
                      },
                      {
                        field: `parts.${idx}.cost.current.perUnit`,
                        headerName: 'на единицу измерения в текущем уровне цен',
                        cellRendererParams: {
                          decimalMax: 6,
                          idx
                        },
                        lockPinned: true,
                        cellRenderer: 'cellRenderer',
                        width: 160,
                        minWidth: 160
                      },
                      {
                        field: `parts.${idx}.cost.current.multiplier`,
                        headerName: 'коэффициенты',
                        cellRendererParams: {
                          decimalMax: 6,
                          idx
                        },
                        lockPinned: true,
                        cellRenderer: 'cellRenderer',
                        width: 103,
                        minWidth: 103
                      },
                      {
                        field: `parts.${idx}.cost.total`,
                        headerName: `${part?.dynamicRows.length ? 'итого' : 'всего'} в текущем уровне цен`,
                        cellRendererParams: {
                          decimalMax: 6,
                          idx
                        },
                        lockPinned: true,
                        cellRenderer: 'cellRenderer',
                        width: 160,
                        minWidth: 160
                      }
                    ]
                  },
                  ...(part?.dynamicRows.length
                    ? part.dynamicRows
                        .map((row, idxD) => ({
                          field:
                            `parts.${idx}.dynamicRows.${idxD}.value` as keyof ExecutionCalculationRimData,
                          columnGroupShow: 'open',
                          // width: 158,
                          // maxWidth: 158,
                          headerComponent: 'customHeaderMultiline',
                          width: 136,
                          minWidth: 136,
                          headerName: `${row.title ?? ''}`,
                          lockPinned: true,
                          cellRenderer: 'cellRenderer',
                          cellRendererParams: {
                            float: 'right',
                            decimalMax: 6,
                            idx
                          }
                        }))
                        .concat([
                          {
                            field:
                              `parts.${idx}.total` as keyof ExecutionCalculationRimData,
                            headerName: 'Всего стоимость',
                            columnGroupShow: 'open',
                            cellRendererParams: {
                              decimalMax: 6,
                              idx
                            },
                            lockPinned: true,
                            cellRenderer: 'cellRenderer',
                            width: 160,
                            minWidth: 160
                          } as any
                        ])
                    : []),
                  {
                    field: `parts.${idx}.tz`,
                    headerName: 'ТЗ',
                    columnGroupShow: 'open',
                    cellRendererParams: {
                      decimalMax: 2,
                      idx
                    },
                    lockPinned: true,
                    cellRenderer: 'cellRenderer',
                    width: 136,
                    minWidth: 136
                  },
                  {
                    field: `parts.${idx}.tzm`,
                    headerName: 'ТЗМ',
                    columnGroupShow: 'open',
                    cellRendererParams: {
                      decimalMax: 2,
                      idx
                    },
                    lockPinned: true,
                    cellRenderer: 'cellRenderer',
                    width: 136,
                    minWidth: 136
                  }
                ] as any)
        });
        return acc;
      },
      []
    ) || []
  );
}

export const useTable = (
  exampleRow: ExecutionCalculationRimData | undefined,
  updateFlag: number,
  fetching: any
): [
  (
    | ColDef<ExecutionCalculationRimData, any>
    | ColGroupDef<ExecutionCalculationRimData>
  )[],
  () => void
] => {
  const flag = useRef(true);
  const [state, setState] = useState<
    (
      | ColDef<ExecutionCalculationRimData, any>
      | ColGroupDef<ExecutionCalculationRimData>
    )[]
  >([]);
  const resetColumns = useCallback(() => {
    flag.current = true;
  }, []);
  useEffect(() => {
    if (!fetching) {
      flag.current = true;
      setState([]);
    }
  }, [updateFlag, fetching]);

  useEffect(() => {
    if (flag.current && exampleRow) {
      flag.current = false;

      setState([
        {
          field: 'lvl',
          pinned: 'left',
          lockPinned: true,
          resizable: false,
          width: 100,
          minWidth: 100,
          maxWidth: 100,
          suppressMovable: true,
          headerName: 'Ур',
          colSpan: (params) => {
            if (params.data?.type === 'limit-header') {
              return params?.columnApi?.getAllColumns()?.length || 1;
            }
            return params.node?.rowPinned === 'top' ? 1 : 0;
          },
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            isLevel: true
          }
        },
        {
          field: 'number',
          pinned: 'left',
          width: 60,
          minWidth: 60,

          // maxWidth: 60,
          // minWidth: 60,
          suppressMovable: false,
          headerName: '№ п\\п',

          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'center',
            isCustomPinned: true
          },
          colSpan: (params) => {
            return params.node?.rowPinned === 'top' ? 7 : 0;
          }
        },
        {
          field: 'lsr',
          pinned: 'left',
          width: 120,
          minWidth: 120,

          // maxWidth: 120,
          // minWidth: 120,
          suppressMovable: false,
          headerName: 'Шифр ЛСР',
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'left'
          }
        },
        {
          field: 'chapter',
          pinned: 'left',
          width: 180,
          minWidth: 180,

          // maxWidth: 180,
          // minWidth: 180,
          suppressMovable: false,
          headerName: 'Раздел',
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'left'
          }
        },
        {
          field: 'header',
          pinned: 'left',
          width: 120,
          minWidth: 120,

          // maxWidth: 120,
          // minWidth: 120,
          suppressMovable: false,
          headerName: 'Заголовок',
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'left'
          }
        },
        {
          field: 'code',
          pinned: 'left',
          width: 235,
          minWidth: 235,

          // maxWidth: 235,
          // minWidth: 235,
          suppressMovable: false,
          headerName: 'Код расценки',
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'left'
          }
        },
        {
          field: 'title',
          pinned: 'left',
          width: 400,
          minWidth: 400,
          // maxWidth: 400,
          // minWidth: 400,
          suppressMovable: false,
          headerName: 'Наименование',
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'left'
          }
        },

        {
          field: 'unit',
          width: 120,
          minWidth: 120,
          // maxWidth: 120,
          // minWidth: 120,
          suppressMovable: false,
          headerName: 'Ед. изм.',
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'center'
          }
        },
        ...generateColumnsParts(exampleRow.parts)
      ]);
    }
  }, [exampleRow, updateFlag]);
  return [state, resetColumns];
};
// export const CellRenderer = (
//   props: ICellRendererParams<ExecutionCalculationRimData, any, any>
// ) => {
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const { current } = props.context;
//   const { idx } = props.context;
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const editable = useMemo(
//     () =>
//       props?.data?.type === 'level' &&
//       (props?.data?.parts[idx]?.type === 'act' ||
//         props?.data?.parts[idx]?.type === 'range') &&
//       !!current &&
//       !!props?.data?.parts[idx]?.startDate &&
//       isSameDay(
//         new Date(current.startDate!),
//         new Date(props?.data?.parts[idx]?.startDate!)
//       ),
//     [props]
//   );
//
//   const handleConfirm = (confirm: boolean) => {
//     if (confirm) {
//       props.colDef!.editable = true;
//       props.api.startEditingCell({
//         rowIndex: props.node.rowIndex!,
//         colKey: props.column || ''
//       });
//     }
//   };
//
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const { ConfirmDialog: WarnDialog, openConfirm: openWarnDialog } =
//     useConfirmDialog({
//       title: 'Подтвердить внесение изменений?',
//       body: 'Данные будут изменены в закрытом периоде.',
//       handleConfirm
//     });
//
//   const value = props.data?.parts[idx]?.quantity;
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   // const renderValue = useMemo(() => {
//   //   const currentQuantity = props.data?.parts[idx];
//   //   let value: string | null = null;
//   //   if (
//   //     currentQuantity?.quantity &&
//   //     typeof currentQuantity?.quantity === 'number'
//   //   ) {
//   //     value = currentQuantity
//   //       ?.quantity!.toString()
//   //       .replace(/\./g, ',');
//   //   } else if (
//   //     currentQuantity?.quantity &&
//   //     typeof currentQuantity?.quantity === 'string'
//   //   ) {
//   //     value = (currentQuantity?.quantity as string).replace(
//   //       /\./g,
//   //       ','
//   //     );
//   //   }
//   //   if (props.data?.id === -1) {
//   //     value = null;
//   //   }
//   //   return value;
//   // }, [props]);
//
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   useEffect(() => {
//     if (current && current.status === 'CLOSED') {
//       props.colDef!.editable = false;
//     }
//   }, [props, current]);
//   return (
//     <Cell
//       click={() => {
//         if (current && current.status === 'CLOSED' && editable) {
//           openWarnDialog(() => {
//             console.log('confirm');
//           });
//         } else {
//           if (editable) {
//             props.colDef!.editable = true;
//             props.api.startEditingCell({
//               rowIndex: props.node.rowIndex!,
//               colKey: props.column?.getColId() || ''
//             });
//           }
//         }
//       }}
//       originData={
//         props.data?.type !== 'file'
//           ? value
//             ? replaceStringFromServer(String(value), decimalMaxTooltip)
//             : null
//           : null
//       }
//       float={'center'}
//       isNum
//       isError={errors[idx] || false}>
//       {props.data?.type !== 'file'
//         ? value
//           ? replaceStringFromServer(String(value), decimalMax)
//           : null
//         : null}
//       {/*{renderValue}*/}
//       <WarnDialog />
//     </Cell>
//   );
// };
export function isHaveColorRow(
  type:
    | 'row'
    | 'level'
    | 'file'
    | 'total'
    | 'limit'
    | 'limit-header'
    | null
    | undefined,
  idPart: number | undefined,
  err: any
) {
  console.log(type, idPart, err);
  return false;
  // if (type === 'file' || type === 'total' || !idPart) return false;
  // return err?.[idPart] || false;
}
export function checkError(parts: PartExecutionRim[]) {
  const result: { [key: string]: boolean } = {};
  let total = 0;
  console.log(parts);
  const temp = parts.reduce(
    (acc, act, index) => {
      if (!acc[index]) {
        acc[index] = {
          value: 0,
          type: '',
          total: 0
        };
        acc[index].type = act.type!;
        if (acc[index].type === 'total') {
          acc[index].value = 0;
          acc[index].total = act.quantity?.total ?? 0;
          total = acc[index].total;
        } else if (acc[index].type === 'act') {
          if (acc[index - 1]) {
            acc[index].value =
              acc[index - 1].value + (act.quantity?.total ?? 0);
            acc[index].total = total;
          }
        } else {
          acc[index].value = act.quantity.total ?? 0;
          acc[index].total = total;
        }
      }

      return acc;
    },
    {} as { [key: string]: { value: number; type: string; total: number } }
  );

  for (const index in temp) {
    const tempKey = Number(index);
    const currentPart = temp[tempKey];

    if (currentPart.type !== 'total') {
      if (currentPart.type !== 'rest') {
        if (currentPart.total > 0) {
          result[tempKey] = currentPart.value > currentPart.total;
        } else {
          result[tempKey] = currentPart.value < currentPart.total;
        }
      } else {
        if (currentPart.total > 0) {
          result[tempKey] = currentPart.value < 0;
        } else {
          result[tempKey] = currentPart.value > 0;
        }
      }
    }
  }
  return result;
}

import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { createStepperContext, useStepper } from 'hooks/useStepper';
import Resources from 'pages/Calculations/components/Resources';
import { IndexMethodCalculation } from 'pages/projects-calculation/index-method-calculation';
import { VorPage } from 'pages/Vor';
import React, { FC, useEffect } from 'react';
import {
  IndexMethodHeader,
  IndexMethodProps,
  IndexMethodWrapper,
  useIndexMethodRest
} from '.';
import { useGetActListQuery } from '../../../api/calculations';
import { OrNull } from '../../../api/references/estimates/estimates.types';
import { CalculationLabel } from '../../../components/CalculationLabel';
import { ActList } from '../../../types';
import { addData, deleteData, getData, openDB } from '../../../utils/indexDB';
import { IndexMethodExecutionTable } from '../index-method-execution';
import { ProjectLabel } from 'components/ProjectLabel';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

type Stepper = {
  indexPage: number;
};
const defaultStateStepper: Stepper = {
  indexPage: 1
};
export const StepperIndexMethodContext = createStepperContext<Stepper>({
  depth: defaultStateStepper,
  maxDepth: defaultStateStepper,
  setMaxDepth: () => {
    return;
  },
  toggleDepth: () => {
    return;
  }
});

/**
 * @description
 * Это РИМ (можно и БИМ сюда бахнуть)
 */
export const IndexMethod: FC<IndexMethodProps> = () => {
  const { calcID, fileID, projectID, mode, calculation, tab, setTab } =
    useIndexMethodRest();
  const { isAddingVor } = useSelector((state: RootState) => state.vor.table);

  const [updateFragment, setUpdateFragment] = React.useState(0);
  const updateFragmentHandler = React.useCallback(() => {
    setUpdateFragment((prevState) => prevState + 1);
  }, []);

  const { data: actList, refetch: refetchActs } = useGetActListQuery(
    { calcID: calculation?.id! || 0 },
    { skip: !calculation?.id }
  );
  const [currentAct, setCurrentAct] = React.useState<OrNull<ActList>>(null);

  const changeCurrentAct = React.useCallback(
    (act: ActList | null) => {
      console.log('props', act);
      openDB().then(async (db) => {
        if (act !== null) {
          addData(db, { id: calcID, act }).then(() => {
            setCurrentAct(act);
          });
        } else {
          deleteData(db, calcID).then(() => {
            setCurrentAct(null);
          });
        }
      });
    },
    [calcID]
  );
  const [firstLoadCalc, setFirstLoadCalc] = React.useState(true);
  const [firstLoadExecuted, setFirstLoadExecuted] = React.useState(true);

  useEffect(() => {
    if (tab !== 'calculation') {
      setFirstLoadCalc(true);
    }
    if (tab !== 'execution') {
      setFirstLoadExecuted(true);
    }
  }, [tab]);

  const toggleFirstLoad = React.useCallback((f?: boolean) => {
    setFirstLoadCalc((prevState) => (f !== undefined ? f : !prevState));
  }, []);
  const toggleFirstExecutedLoad = React.useCallback((f?: boolean) => {
    setFirstLoadExecuted((prevState) => (f !== undefined ? f : !prevState));
  }, []);

  const stepperProps = useStepper<Stepper>(defaultStateStepper);

  useBreadcrumbs(
    [
      { title: <ProjectLabel /> },
      { title: `Расчеты`, url: `/projects/${projectID}/calculations` },
      {
        title:
          mode === 'viewer' ? (
            'Предпросмотр'
          ) : (
            <CalculationLabel
              title={calculation?.title}
              type={calculation?.type}
            />
          ),
        MenuOpen: true
      }
    ],
    [calculation, projectID, mode, tab],
    tab === 'enlarged'
  );
  React.useEffect(() => {
    if (calcID && actList) {
      openDB().then(async (db) => {
        const result = await getData(db, calcID);
        if (result) {
          const find = actList.actList?.find((_) => _?.id === result?.act?.id);
          if (find) {
            console.log('props', 1, find);
            changeCurrentAct(find);
          } else {
            console.log('props', 2);
            changeCurrentAct(result.act);
          }
        } else {
          console.log('props', 3);

          const lastAct = actList.actList[actList.actList.length - 1];
          addData(db, { id: calcID, act: lastAct }).then(() => {
            changeCurrentAct(lastAct);
          });
        }
      });
    }
  }, [calcID, actList]);
  return (
    <StepperIndexMethodContext.Provider value={stepperProps}>
      {isAddingVor
        ? null
        : mode === 'editor' && (
            <IndexMethodHeader
              updateFragment={updateFragmentHandler}
              currentAct={currentAct}
              actList={actList}
              refetchActs={refetchActs}
              changeCurrentAct={changeCurrentAct}
              projectID={projectID}
              calcID={calcID}
              dataCalculation={calculation}
              tab={tab}
              setTab={setTab}
            />
          )}
      <IndexMethodWrapper $isGrid={tab !== 'enlarged'}>
        {tab === 'calculation' ? (
          <IndexMethodCalculation
            updateFragment={updateFragment}
            firstLoad={firstLoadCalc}
            toggleFirstLoad={toggleFirstLoad}
            projectID={projectID}
            calcID={calcID}
            fileID={fileID}
            mode={mode}
          />
        ) : tab === 'enlarged' ? (
          <VorPage calculation={calculation} />
        ) : tab === 'resource' ? (
          <div style={{ paddingTop: '10px' }}>
            <Resources basePrice={false} margin={true} />
          </div>
        ) : (
          tab === 'execution' && (
            <IndexMethodExecutionTable
              updateFragment={updateFragment}
              firstLoad={firstLoadExecuted}
              currentAct={currentAct}
              changeCurrentAct={changeCurrentAct}
              toggleFirstLoad={toggleFirstExecutedLoad}
              calculation={calculation}
              actList={actList}
              refetchActs={refetchActs}
              calcID={calcID}
              projectID={projectID}
            />
          )
        )}
      </IndexMethodWrapper>
    </StepperIndexMethodContext.Provider>
  );
};
